import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss']
})
export class QuantitySelectorComponent implements OnInit {
	@Output() roomCountChange = new EventEmitter<number>();
	@Input() roomCount: number;
  constructor() { }

  ngOnInit(): void {
  }

  increment() {
		this.roomCount++;
		this.updateValue();
	}

	decrement() {
		if (this.roomCount > 1) {
			this.roomCount--;
		}
	}

	updateValue(){
		this.roomCountChange.emit(this.roomCount);
	}

}
