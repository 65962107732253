<p-editor #editorComponent (onInit)="onEditorInit($event)" [formControl]="control"
        [style]="{'height': inputHeight + 'px'}" styleClass="editor">
        <ng-template pTemplate="header">
                <span class="ql-formats">
                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                        <span class="ql-format-separator"></span>
                        <select title="Text Colour" class="ql-color" defaultValue="rgb(0, 0, 0)">
                                <option value="rgb(0, 0, 0)" label="Black"></option>
                                <option value="rgb(31, 182, 255)" label="Blue"></option>
                                <option value="rgb(19, 206, 102)" label="Green"></option>
                                <option value="rgb(255, 73, 73)" label="Green"></option>
                        </select>
                        <select title="Background Colour" class="ql-background" defaultValue="rgb(255, 255, 255)">
                                <option value="rgb(250, 250, 5)" label="Yellow highlight"></option>
                                <option value="rgb(0, 255, 217)" label="Green highlight"></option>
                        </select>
                        <span class="ql-format-separator"></span>
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                </span>
        </ng-template>

</p-editor>
