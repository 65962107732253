import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import {MatSliderModule} from "@angular/material/slider";
import {ReactiveFormsModule} from "@angular/forms";
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import {RouterModule} from "@angular/router";
import {SharedModule} from "@shared/shared.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { CheckboxModule } from 'primeng/checkbox';



@NgModule({
    declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, ActivateAccountComponent],
    exports: [

    ],
	imports: [
		CommonModule,
		MatSliderModule,
		ReactiveFormsModule,		
		RouterModule,
		SharedModule,
		FlexLayoutModule,
		MatCheckboxModule,
		CheckboxModule

	]
})
export class AuthModule { }
