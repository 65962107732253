<!-- mobile -->
<div fxHide.gt-xs class="stepper-step-container" fxLayout="row" fxLayoutAlign="none center" [ngClass]="size">
	<div fxFlex="auto" fxLayout="row" fxLayoutAlign="none center">
		<ng-container *ngFor="let step of steps | StepVisibleFilter;  index as i">
			<div fxFlex="none" fxLayout="row" fxLayoutAlign="none center"
					 (click)="goToStep(i, step)"
					 class="step-completed-mobile"
					 [@pulseOnEnter]
					 [ngClass]="{'step-completed': step.completed}" *ngIf="(i === selectedIndex) || (step.completed && i <= selectedIndex)">
				<div fxLayout="column" fxLayoutAlign="center center" *ngIf="!step.completed" class="step-number">
					<div fxLayout="row" fxLayoutAlign="center center">
						{{ i + 1 }}
					</div>
				</div>
				<div class="complete-icon" *ngIf="step.completed">
					<mat-icon svgIcon="check-circle-solid"></mat-icon>
				</div>
			</div>
			<div fxFlex="none"
					 class="breadcrumb-title"
					 (click)="goToStep(i, step)"
					 *ngIf="i === selectedIndex"
					 [ngClass]="{'step-completed': step.completed, 'active': selectedIndex === i}">{{ step.breadcrumb }}</div>
		</ng-container>
	</div>
	<div fxFlex="none"  fxLayout="row" fxLayoutAlign="end center" class="uncompleted-steps">
		<ng-container *ngFor="let step of steps | StepVisibleFilter;  index as i">
			<div fxFlex="none" fxLayout="row" fxLayoutAlign="none center"
					 (click)="goToStep(i, step)"
					 class="step-completed-mobile"
					 [ngClass]="{'step-completed': step.completed}" *ngIf="(i > selectedIndex) && step.visible">
				<div fxLayout="column" fxLayoutAlign="center center"  class="step-number --step-number-disabled">
					<div fxLayout="row" fxLayoutAlign="center center">
						{{ i + 1 }}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<!-- desktop -->
<div fxHide.xs class="stepper-step-container">
	<div fxLayout="row" fxLayoutAlign="none center">
		<ng-container *ngFor="let step of steps | StepVisibleFilter;  index as i">
			<div fxFlex="none" (click)="goToStep(i, step)" [ngClass]="{'step-completed': step.completed}" *ngIf="step.visible">
				<div fxLayout="column" fxLayoutAlign="center center" *ngIf="!step.completed" class="step-number">
					<div fxLayout="row" fxLayoutAlign="center center">
						{{ i + 1 }}
					</div>
				</div>
				<div class="complete-icon" *ngIf="step.completed">
					<mat-icon svgIcon="check-circle-solid"></mat-icon>
				</div>
			</div>
			<div fxFlex="none" class="breadcrumb-title" *ngIf="step.visible" (click)="goToStep(i, step)" [ngClass]="{'step-completed': step.completed, 'active': selectedIndex === i}">{{ step.breadcrumb }}</div>
		</ng-container>
	</div>

</div>
