import { Injectable } from '@angular/core';
import {RequestService} from '../http/request.service';
import {BehaviorSubject, iif, Observable, of, Subject} from 'rxjs';
import {StorageMap} from '@ngx-pwa/local-storage';
import {catchError, filter, first, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {User} from '@models/user/user';
import {Role} from "@models/user/role";
import {Permission, PermissionItem} from "@models/user/Permission";
import * as _ from "underscore";
import { CookieService } from '@shared-services/cookie/cookie.service';
import { TWOFA_EXPIRY } from '../constants/app.constants';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser = new Observable<User>();
  public isUserOnlineSubject: BehaviorSubject<any>;
  public isUserOnline = new Observable<any>();
  private authenticated = false;
  public onAuthFail:Subject<void> = new Subject<void>();
  private userPermissions:Array<string> = [];
  private authStateChange: Subject<any> = new Subject<any>();
  private adminSettingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public adminSettings = new Observable< { [key: string]: boolean | string | number } >();
  constructor(
  	private request: RequestService,
		 private storage: StorageMap,
		 private router: Router,
     private cookieService: CookieService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();
    this.isUserOnlineSubject = new BehaviorSubject<any>(null);
    this.isUserOnline = this.isUserOnlineSubject.asObservable();
    this.adminSettings = this.adminSettingsSubject.asObservable();
    this.retrieveUserFromStorage$();

    this.authStateChange.pipe(
      switchMap((isAuthenticated) => iif(()=> isAuthenticated, this.appSettings(), of({})))
    ).subscribe(app_settings => this.adminSettingsSubject.next(app_settings))   
  }

  appSettings(){
    return this.request.get(`api/admin/app-settings`)
    .pipe(
      map(res => res.app_settings)
    );
  }

  retrieveUserFromStorage$() {
    return this.storage.get('currentUser').pipe(first(),
      map((user: User) => {
        return user;
      }),
    );
  }

  authenticate(credentials) {
    return this.request.post('api/authenticate', credentials).pipe(map(res => {
      if (! res.auth_2fa_required) {
        if(res.auth_2fa_secret){
          this.cookieService.setCookie('2faSecret',res.auth_2fa_secret, TWOFA_EXPIRY);
        }
        return this.setUser(res.user);
      }
      return false;
    }));
  }

  register(params) {
  	return this.request.post('api/register', params).pipe(map(res => {
			return this.setUser(res.user);
		}));
	}

  forgotPassword(email) {
    return this.request.post('api/forgot-password', {email: email});
  }

  validatePasswordToken(token) {
    return this.request.post('api/validate-password-token', {token: token}).pipe(map(res => {
      return res.user as User;
    }));
  }

  resetPassword(params: { password: string; token: string }) {
    return this.request.post('api/reset-password', params);
  }

	validateAccessToken(key) {
		return this.request.post('api/validateActivateToken', {key: key}).pipe(map(res => {
			return res.user;
		}));
	}

	activateAccount(params) {
		return this.request.post('api/activateAccount', params).pipe(map(res => {
			return this.setUser(res.user);
		}));
	}


  get isAuthenticated(): Observable<boolean> {
    // Are we authenticated already?
    // Here we'll check our local saved variable first, if it's false and we've landed on an authenticated route
    // then we'll want re-authenticate
    if (this.authenticated) {
      return of(true);
    } else {
      return this.request.post('api/authenticate').pipe( // we don't set credentials as we'll auto authenticate with token alone
        map(res => {
          if (res.valid) {
            this.setUser(res.user);
            return true;
          } else {
            this.authStateChange.next(false);
            this.router.navigate(['/login']);
            return false;
          }
        }),
        catchError(() => {
            // this.router.navigate(['/login']);
            return of(false);
        }));
    }
  }

  checkTokenValidation(){
    return this.request.post('api/authenticate').pipe(
        map(res => {
          if (res.valid) {            
            return true;
          } else {
            this.onAuthFail.next();
            this.router.navigate(['/login']);
            return false;
          }
        }),
        catchError(() => {
            this.onAuthFail.next();
            this.router.navigate(['/login']);
            return of(false);
        }));
  }

  logout() {
    this.storage.clear().subscribe(() => {
      this.authenticated = false;
      this.router.navigate(['/login']);
    });
    return this.request.post('api/logout').subscribe();
  }



  private setUser(_user) {
    const user = _user;
    this.storage.set('currentUser', user).subscribe();
    this.userPermissions = user.permission_list || [];
    this.authenticated = true;
    this.currentUserSubject.next(user);
    this.authStateChange.next(true);
    return user;
  }

  public hasSinglePermission(permission: string): Observable<boolean> {
    return this.currentUser.pipe(
      filter(res => {
        return typeof res !== 'undefined' && res !== null;
      }), switchMap((user: User) => {
        return of(user.permission_list.indexOf(permission) !== -1);
      }));
  }

  public hasMultiplePermissions(permissions: Array<string>): Observable<PermissionItem[]> {
      if (!(permissions instanceof Array)) {
        permissions = [permissions];
      }
      return this.currentUser.pipe(
        filter(res => {
          return typeof res !== 'undefined' && res !== null;
        }), switchMap((user: User) => {
          let permissionResult = Array<PermissionItem>();
          const userPermissions = user.permission_list;
          permissions.forEach((perm) => {
            let permissionExists = userPermissions.indexOf(perm) !== -1
            let permResult = {
                  permission: perm,
                  access: permissionExists,
                } as PermissionItem;
            permissionResult.unshift(permResult);
          });

          return of(permissionResult);
        }));
  }

  public isAdmin(): Observable<boolean> {
    return this.currentUser.pipe(
      filter(res => {
        return typeof res !== 'undefined' && res !== null;
      }), switchMap((user: User) => {
        const roles = user.roles as Role[];
        let notAdmin = false;
        if (roles.length) {
          roles.forEach((role) => {
            if (role.is_customer === 1) {
              notAdmin = true;
              console.log('User has a non-admin role - logout immediately');
              return false;
            }
          });
        }
        if (notAdmin) {
          return of(false);
        } else {
          return of(true);
        }
      }));
  }

  checkPermission(permission:string){
    return this.userPermissions.indexOf(permission) !== -1;
  }


  //
  // is(roles, all) {
  //   if (!angular.isObject(user)) { return; }
  //   if (user.role_list === 'Admin') { return true; }
  //
  //   const requiredRoles = angular.isArray(roles) ? roles : [roles];
  //   const matchAll = Boolean(all);
  //   const requiredCount = requiredRoles.length;
  //   let matchCount = 0;
  //   const userRoles = user.role_list;
  //
  //   angular.forEach(requiredRoles, function(value) {
  //     if (userRoles.indexOf(value) >= 0) {
  //       matchCount++;
  //     }
  //   });
  //
  //   if ((matchAll === true && requiredCount === matchCount) || (matchAll === false && matchCount > 0)) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }


}
