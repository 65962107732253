import {Injectable} from '@angular/core';
import {RequestService} from '../../http/request.service';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class FileService {
  constructor(private request: RequestService) {}

  download(fileId) {
    return this.request.post('api/file/get-download-key', {
      file_id: fileId,
    }).pipe(map(res => {
      return res.download_key as string;
    }));
  }

  view(fileId) {
    return this.request.post('api/file/view', {
      file_id: fileId,
    }).pipe(map(res => {
      return res.url as string;
    }));
  }
}
