import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TwoStepVerificationService } from '@shared-services/two-step-verification/two-step-verification.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-two-step-verification',
  templateUrl: './two-step-verification.component.html',
  styleUrls: ['./two-step-verification.component.scss']
})
export class TwoStepVerificationComponent implements OnInit {

  authData: any;
  loading = false;
  code: any;
  errorMessage = false;

  constructor(
    private authenticationService: TwoStepVerificationService,
    public ref: DynamicDialogRef,
    protected cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getQRCode();
  }

  getQRCode() {
    this.authenticationService.setup().subscribe(res => {
      this.authData = {
        secret: res.secret,
        qr_code: res.qr_code,
        image: res.image
      };
      this.loading = false;
    });
  }

  submitOTP() {
    this.errorMessage = false;
    this.authenticationService.verify({code: this.code}).subscribe(res => {
      if (res.success) { this.ref.close(res.success); }
    }, error => {
      this.errorMessage = true;
    });
  }

}
