export enum Icons {
  'check-regular' = '',
  'bars-regular' = '',
  'check-circle-solid' = '',
  'water' = '',
  'home-duotone' = '',
  'sign-duotone' = '',
  'building-duotone' = '',
  'minus-solid' = '',
  'plus-solid' = '',
  'times-regular' = '',
  'chevron-right-solid' = '',
  'chevron-left-solid' = '',
  'chevron-down-solid' = '',
  'chevron-up-solid' = '',
  'search-solid' = '',
  'filter-solid' = '',
  'magnifying-glass-light' = '',
  'ellipsis-h-light' = '',
  'info-circle-duotone' = '',
  'money-check-edit-duotone' = '',
  'credit-card-blank-regular' = '',
  'balance' = '',
  'statement' = '',
  'next-payment' = '',
  'user-duotone' = '',
  'user-solid' = '',
  'lightbulb-duotone' = '',
  'exclamation-triangle-duotone' = '',
  'download-solid' = '',
  'cogs-duotone' = '',
  'layer-group-duotone' = '',
  'comment-dots-duotone' = '',
  'pause-circle-duotone' = '',
  'stop-circle-duotone' = '',
  'bolt-duotone' = '', // Electric
  'tint-duotone' = '', // Water
  'burn-duotone' = '', // Gas
  'wifi-2-duotone' = '', // Broadband
  'tv-retro-duotone' = '', // TV Licence
  'pound-sign-light' = '',
  'suitcase-light' = '',
  'envelope-open-light' = '',
  'user-light' = '',
  'sack-light' = '',
  'check-double-light' = '',
  'file-signature-light' = '',
  'concierge-bell-light' = '',
  'tasks-alt-light' = '',
  'piggy-bank-light' = '',
  'ellipsis-h-alt-light' = '',
  'chart-line-light' = '',
  'wrench-light' = '',
  'trash-alt-light' = '',
  'line-columns-duotone' = '',
  'comment-alt-lines-duotone' = '',
  'tachometer-alt-fast-duotone' = '',
  'ellipsis-v-light' = '',
  'times-light' = '',
  'times-circle-solid' = '',
  'draw-circle-light' = '',
  'eraser-light' = '',
  'magic-light' = '',
  'door-closed-light' = '',
  'door-open-light' = '',
  'comment-dots-light' = '',
  'sticky-note-light' = '',
  'snooze-light' = '',
  'snoozed' = '',
  'alarm-plus-light' = '',
  'arrow-square-right-light' = '',
  'project-diagram-light' = '',
  'copy-light' = '',
  'paper-plane-light' = '',
  'long-arrow-right-light' = '',
  'paperclip-light' = '',
  'repeat-alt-light' = '',
  'copy' = '',
  'filter' = '',
  'filter-light' = '',
  'check-circle-duotone' = '',
  'question-circle-duotone' = '',
  'check-circle-light' = '',
  'calling' = '',
  'phone' = '',
  'end-call' = '',
  'call-forwarding' = '',
  'Setting' = '',
  'divert' = '',
  'user-square' = '',
  'microphone-mute' = '',
  'microphone-unmute' = '',
  'dial' = '',
  'call-accept' = '',
  'phone_blue'  = '',
  'incoming-call' = '',
  'outgoing-call' = '',
  'external-link-light' = '',
  'clear-ico' = '',
  'receipt-light' = '',
  'file-chart-column-light' = '',
  'cart-shopping-fast-light' = '',
  'envelope-light' = '',
  'minus' = '',
  'plus' = '',
  'arrow-right' = '',
  'correct' = '',
  'pencil-edit' = '',
  'expected-payments' = '',
  'phone-call' = '',
  'file' = '',
  'new-tab' = '',
  'address-book-solid' = '',
  'eye-solid' = '',
  'eye-slash-solid' = '',
  'key-solid' = '',
  'sort-down-solid' = '',
  'arrow-rotate-right-solid' = '',
  'arrow-up-right-and-arrow-down-left-from-center-solid' = '',
  'user-secret-solid' = '',
  'note-solid' = '',
  'list-check-solid' = '',
  'diagram-subtask-solid' = '',
  'envelope-solid' = '',
  'pencil-solid' = '',
  'notes-solid' = '',
  'reply-solid' = '',
  'floppy-disk-regular' = '',
  'briefcase-light' = '',
  'house-light' = '',
  'gear-light' = '',
  'link-thin' = '',
  'input-numeric-light' = '',
  'circle-pause-solid' = '',
  'circle-play-solid' = '',
  'chart-gantt-solid' = '',
  'arrow-left-regular' = '',
  'arrow-right-sharp-regular' = '',
  'recon' = '',
  'circle-xmark-duotone' = '',
  'file-attachment' = '',
  'person-simple-thin' = '',
}
