<div [formGroup]="form">
  <div class="input-group">
    <label for="line_1" class="required">Address</label>
		<input type="text" id="line_1" name="line_1" placeholder="" formControlName="line_1" class="input input-m">
  </div>
  <div class="input-group">
		<input type="text" name="line_2" placeholder="" formControlName="line_2" class="input input-m">
  </div>
  <div class="input-group">
      <input type="text" name="line_3" placeholder="" formControlName="line_3" class="input input-m">
  </div>
  <div class="input-group">
		<label for="city" class="required">City</label>
		<input type="text" id="city" name="city" placeholder="" formControlName="city" class="input input-m">
  </div>
  <div class="input-group">
    <label for="postcode" class="required">Postcode</label>
		<input type="text" id="postcode" name="postcode" placeholder="" formControlName="postcode" class="input input-m input-small">
  </div>
</div>
