import {Injectable} from '@angular/core';
import {RequestService} from '../../http/request.service';
import {map} from 'rxjs/operators';
import {Property} from '@models/property/property';
import {AccountService} from '../account/account.service';
import {PropertyIndexResponse, PropertyOptionsResponse} from "@models/property/response-types";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  private propertyDeletedSource = new Subject<object>();
  private propertyUpdatedSource = new Subject<object>();
  propertyDeleted$ = this.propertyDeletedSource.asObservable();  
  propertyUpdated$ = this.propertyUpdatedSource.asObservable();
  constructor(private request: RequestService, private accountService: AccountService) {
  }

  index(params):Observable<PropertyIndexResponse>{
    return this.request.get('api/admin/property/index', params)
     // .pipe(map((res: PropertyIndexResponse) => res.property_collection));
  }

  save(params) {
    return this.request.post('api/admin/property/save', params).pipe(map(res => {
      return res;
    }));
  }

  globalSearch(params) {
    return this.request.post('api/admin/property/global-search', params);
  }

  read(id) {
    return this.request.get(`api/admin/property/read/${id}`).pipe(map(res => {
      return res.property as Property;
    }));
  }

  list(params) {
    return this.request.get('api/admin/property/options', params)
      .pipe(map((res: PropertyOptionsResponse) => res.property_options));
  }

  import(params) {
    return this.request.post('api/admin/property/import', params);
  }

  importV2(params) {
    return this.request.post('api/admin/property/import/v2', params);
  }

  validate(params){
    return this.request.post('api/admin/property/import/validate', params);
  }

  delete(id) {
    return this.request.post('api/admin/property/delete/' + id);
  }

  propertyDeleted(propertyId) {
    this.propertyDeletedSource.next(propertyId);
  }

  propertyUpdated(property: Property){
    this.propertyUpdatedSource.next(property);
  }

  createPropertyEligibility(propertyId){
    return this.request.get(`api/admin/property/eligibility/from-property/${propertyId}`);
  }

  propertyExport(params){
    return this.request.get(`api/admin/property/export`, params);
  }

}
