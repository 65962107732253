import { Injectable } from '@angular/core';
import { RequestService } from '../../http/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TwoStepVerificationService {
  apiPrefix: string;

  constructor(private request: RequestService) {
    this.apiPrefix = 'api/auth/2fa/';
  }

  setup() {
    return this.request.get(this.apiPrefix + 'setup').pipe(map(res => {
      return res;
    }));
  }

  verify(params) {
    return this.request.post(this.apiPrefix + 'verify', params).pipe(map(res => {
      return res;
    }));
  }
}
