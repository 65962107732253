import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subject } from 'rxjs';
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  private versionReadySource = new Subject<boolean>();
  versionReady$ = this.versionReadySource.asObservable();

  constructor(
    private update: SwUpdate,
  ) {
    this.updateInit();
  }
  updateInit() {
    console.log('update enabled: ', this.update.isEnabled)
    if (!this.update.isEnabled)
      return;

    this.checkForUpdate()
    this.update.versionUpdates
      .pipe(
        tap(update => {
          if (update.type === 'VERSION_DETECTED') {
            // activateUpdate() will trigger the 'VERSION_READY' or 'VERSION_INSTALLATION_FAILED' event when done
            //New server version detected, trying to install...'
            this.update.activateUpdate().then();
          }
          if (update.type === 'VERSION_READY') {
            //asking a full page reload            
            this.versionReadySource.next(true);            
          }
          if (update.type === 'VERSION_INSTALLATION_FAILED') {            
            console.warn('Error while installing update, cache should be cleared and page reloaded');            
          }
          // another event type possible is 'NO_NEW_VERSION_DETECTED', we don't need to handle it
        })
      )
      .subscribe();
  }
  checkForUpdate() {
    const updateInterval$ = interval(1 * 60 * 60 * 1000);
    updateInterval$.subscribe(() => { this.update.checkForUpdate() });

  }
}
