import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";

@Injectable({
  providedIn: 'root'
})
export class StorageHelperService {

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
  ) {
  }

  public saveFilters(name: string, filters: any) {
    this.storage.set(name, {...filters});
  }

  retrieveFilters(name: string) {
    if (this.storage.has(name)) {
      return this.storage.get(name);
    } else {
      return null;
    }
  }

  set(name: string, value: any) {
    this.storage.set(name, value);
  }

  setObject(name: string, value: any) {
    const now = new Date();
    now.setHours(22);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    this.storage.set(name, {...value, ...{expiry: now.getTime()}});
  }

  remove(key: string) {
    this.storage.remove(key);
  }

  get(name: string) {
    if (this.storage.has(name)) {
      return this.storage.get(name);
    } else {
      return null;
    }
  }
}
