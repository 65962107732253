export const environment = {
  production: true,
  baseUrl: 'https://agent-sandbox-api.utilitymix.com/',
  appUrl: 'https://admin-sandbox.utilitymix.com/',
  s3bucket: 'https://utilitymix-resources.s3-eu-west-1.amazonaws.com/',
  appName: 'UtilityMix',
  emailContractDomain: 'contracts.utilitymix.com',
  emailDomain: 'umbills.com',
  logo: 'um-white.png',
  readingUrl: 'https://reading-staging.utilitymix.com/public/add',
  readingHelpUrl: 'https://doc.clickup.com/p/h/q26x-1108/e112b0c95d3d9a9',
  appLogoUrl: 'https://utilitymix-resources.s3-eu-west-1.amazonaws.com/logos/um-slack.png',
  appLongLogoUrl: 'https://utilitymix-resources.s3.eu-west-1.amazonaws.com/logos/logo.png',
  favIcon: 'https://utilitymix-resources.s3-eu-west-1.amazonaws.com/favicon.um.ico',
  loadingGif: 'page-loader-1.gif',
  pusherDebug: true,
  pusherKey: '334555d76e3ca6f60dc7',
  features: {
    auth: {
      invite_code: true,
    },
    onboarding: {
      isAgent: true,
    },
  }
};
