import { Component } from '@angular/core';
import { fadeAnimation } from "./shared/animations/fade.animation";
import { Title } from "@angular/platform-browser";
import { environment } from "@environment";
import { CheckForUpdateService } from '@shared/services/check-for-update.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { iif, interval } from 'rxjs';
import { map,  switchMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
  providers: [ConfirmationService, MessageService]
})
export class AppComponent {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon'); 
  constructor(private titleService: Title, checkForUpdateService: CheckForUpdateService, private authService: AuthenticationService, private messageService: MessageService) {
    this.titleService.setTitle(environment.appName + " | Admin");
    this.favIcon.href = environment.favIcon;
    checkForUpdateService.versionReady$.subscribe(() => {
      this.promptUser();
    })
    
    this.checkUserAuth();
  }


  promptUser(): void {    
    this.messageService.add({ severity: 'info', key:"confirmVersionUpdate",  sticky: true });
  }

  onConfirm(){
    window.location.reload();
  }

  checkUserAuth(){
    const authCheckTimer$ = interval(15 * 60 * 1000).pipe(
      switchMap(()=> this.authService.checkTokenValidation()),
      takeUntil(this.authService.onAuthFail)
    );

    this.authService.currentUserSubject
    .pipe(      
      map((user)=> !!user),
      switchMap(
        (authenticated)=> iif(()=> authenticated, authCheckTimer$),
      )      
    ).subscribe();
  }
}