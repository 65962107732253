import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputFilter',
  pure: true
})
export class InputFilterPipe implements PipeTransform {

  transform(value: any, searchValue): any {
    if (!searchValue) {
      return value;
    } else {
      return value.filter((v) => v.value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
    }
  }
}
