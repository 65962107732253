<div fxLayout="column" *ngFor="let row of rows; let i = index" class="gantt-chart-row" [ngClass]="{'expanded': row.expanded}">
<div  fxLayout="row">
  <div class="row-title" fxLayout="row" fxLayoutAlign="none center" (click)="row.expanded = !row.expanded" [ngClass]="{'expandable': row?.rows?.length, 'expanded': row.expanded}">
    <div fxFlex="auto" [style]="{'padding-left': row.indent }">
      <div *ngIf="row.logoPath"><img [src]="row.logoPath" class="logo"></div>
      <div class="title-label" [ngClass]="row.classes" *ngIf="row.model"><a (click)="nameClick($event, row)">{{row.name}}</a>{{row.classes}}</div>
      <div class="title-label" [ngClass]="row.classes" *ngIf="!row.model">{{row.name}}</div>
      <div class="title-sub-label" *ngIf="row.model"><a (click)="subNameClick($event, row)">{{ row.subName}}</a></div>
      <div class="title-sub-label" *ngIf="!row.model">{{ row.subName}}</div>
    </div>
    <div fxFlex="none" *ngIf="row?.rows?.length" class="expand-column" [ngClass]="{'expanded': row.expanded}"><mat-icon svgIcon="chevron-up-solid"></mat-icon> </div>
  </div>
  <div class="row-events">
    <!-- event period blocks-->
    <div class="event-period tooltip contract-{{event.model.account_id}}" highlightEvent [account_id]="'.contract-'+event.model.account_id" *ngFor="let event of row.events" [ngClass]="{'extends-left': event.extendsLeft, 'extends-right': event.extendsRight, 'contract-event':event.model.account_id}"
         [style.margin-left]="getEventOffsetPercentage(event.startDate)+'%'"
         [style.width]="getEventDurationPercentage(event) + '%'" [style.background]="getBackgroundColor(event)" (click)="eventClick(event)">
      <span class="event-name" [ngClass]="event.classes" [style.color]="getForegroundColor(event)" *ngIf="!event.inFuture">
        <span *ngIf="event.type === 'string'">{{event.name}}</span>
        <span *ngIf="event.type === 'currency'">{{event.name | currency}}</span>
      </span>
      <div class="tooltip-text">
<!--        <div><strong>{{event.name}}: </strong></div>-->
        <span *ngIf="event.tooltipText">{{ event.tooltipText }}</span>
        <span *ngIf="!event.tooltipText">{{event.startDate | date:'mediumDate'}} - {{event.endDate | date:'mediumDate'}}</span>
      </div>
    </div>

    <!-- event milestones-->
    <div class="event-milestone tooltip"
         *ngFor="let milestone of row.mileStones"
         [style.margin-left]="getEventOffsetPercentage(milestone.date)+'%'">
      *
      <div class="tooltip-text">
        <div><strong>{{milestone.name}}: </strong></div>
        {{milestone.date | date:'mediumDate'}}
      </div>
    </div>

    <!-- gantt chart grid lines-->
    <div class="grid-lines">
                <span class="grid-line" *ngFor="let month of monthAxis"
                      [style.width]="month.monthDurationPercentage + '%'" [ngClass]="{'current': month.monthName + ' ' + month.monthYear === currentMonth}"></span>
    </div>
  </div>
</div>
  <div class="indent">
    <app-utilify-gantt-row *ngIf="row.expanded" [rows]="row.rows" [chartPeriodDays]="chartPeriodDays" [monthAxis]="monthAxis" [startDate]="startDate"></app-utilify-gantt-row>
  </div>
</div>
