import { Injectable, NgZone } from '@angular/core';
import Pusher from "pusher-js";
import { environment } from "@environment";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { User } from "@models/user/user";
import { filter } from "rxjs/operators";
import { PushNotificationsService } from "@shared/services/PushNotificationService";
import Echo from 'laravel-echo'
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from 'rxjs';

(window as any).global = window;
declare var require: any;

declare global {
  interface Window {
    io: any;
  }

  interface Window {
    Echo: any;
  }
}

window.io = window.io || require('socket.io-client');
window.Echo = window.Echo || {};


export interface PushMessage {
  title: string;
  message: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {

  pusher;
  user: User;
  publicChannel;
  privateChanel;
  twilioChannel;
  supplierPortalChannel;
  incomingCall = {
    status: false,
    type: ''
  };
  twilioQueue = {
    status: false,
    data: {sales: 0, support: 0}
  };
  presenceChannel: any;
  presenceChannels:any = {};
  incomingCallBehavior: BehaviorSubject<any>;
  incomingCall$ = new Observable<any>();
  twilioQueueBehavior: BehaviorSubject<any>;
  twilioQueue$ = new Observable<any>();
  stopRingingBehavior: BehaviorSubject<any>;
  stopRinging$ = new Observable<any>();
  generalEvents: Subject<any> = new Subject();
  generalEvents$ = new Observable<any>();
  private pusherGeneralChannel;
  private _pusher
  constructor(
    private authService: AuthenticationService,
    private pushService: PushNotificationsService,
    private router: Router,
    private zone: NgZone,
  ) {
    this.incomingCallBehavior = new BehaviorSubject<any>(this.incomingCall);
    this.incomingCall$ = this.incomingCallBehavior.asObservable();
    this.stopRingingBehavior = new BehaviorSubject<any>({status: false});
    this.stopRinging$ = this.stopRingingBehavior.asObservable();
    this.twilioQueueBehavior = new BehaviorSubject<any>(this.twilioQueue);
    this.twilioQueue$ = this.twilioQueueBehavior.asObservable();
    this.generalEvents$ = this.generalEvents.asObservable();


    this._pusher = new Pusher('')
    Pusher.logToConsole = environment.pusherDebug;
    this.authService.currentUser.pipe(
      filter(user => {
        return typeof user !== 'undefined' && user !== null;
      })
    )
      .subscribe((user: User) => {
        if (!this.user || this.user.id !== user.id) {
          this.user = user;
          window.Echo = new Echo({
            broadcaster: 'pusher',
            key: environment.pusherKey,
            cluster: 'eu',
            forceTLS: true,
            authEndpoint: environment.baseUrl + 'api/broadcasting/auth',
            encrypted: true,
            auth: {
              headers: {
                AccessToken: user.token,
                "ngsw-bypass": "",
              },
            },
          });

          this.bindToChannels();
          this.handleEvents();
        }
      });
  }

  private bindToChannels() {
    this.bindToPublic();
    this.bindToPrivate();
  }

  bindToPublic() {
    this.publicChannel = window.Echo.channel('public-channel');
    this.pushService.requestPermission();
  }

  private bindToPrivate() {
    this.privateChanel = window.Echo.private(`App.User.${this.user.id}`);
    this.twilioChannel = window.Echo.private('Twilio');
    //this.supplierPortalChannel = window.Echo.private('SupplierPortal');
    this.pusherGeneralChannel = window.Echo.private('General');
  }

  private handleEvents() {
    this.publicChannel.listen('.global-event', (pushMsg: PushMessage) => {
      let data = {
        'title': pushMsg.title,
        'alertContent': pushMsg.message,
        'url': pushMsg.url,
      };
      this.pushService.generateNotification(data).subscribe();
    });

    this.pusherGeneralChannel.listen('.general',(pushMsg)=>{
      this.generalEvents.next(pushMsg);   
    })

    this.twilioChannel.listen('.call-status-changed', (pushMsg) => {
      this.twilioQueueBehavior.next({
        status: true,
        data: pushMsg
      });
    });

    this.privateChanel.listen('.personal-general-event', ((pushMsg: PushMessage) => {
      let data = {
        'title': pushMsg.title,
        'alertContent': pushMsg.message,
        'url': pushMsg.url,
      };
      this.pushService.generateNotification(data).subscribe((res) => {
        let options = res.options;
        let url = options.url;
        if (url && res.event && res.event.type && res.event.type === 'click') {
          this.zone.run(() => {
            this.router.navigateByUrl(url);
          });
        }
      }, (error) => {
        console.log('Notification error', error);
      });
    }));
  }

  receiveCall(callback: Function) {
    // Pusher app key will be provided by the backend
  }

  joinToPresence() {
    if (!this.presenceChannel) {
      this.presenceChannel = window.Echo.join(`Twilio`);
    }
  }

  leveFromPresence() {
    window.Echo.leave(`Twilio`);
    this.presenceChannel = undefined;
  }

  joinToPresenceByChannelName(channelName){
    if(!this.presenceChannels[channelName]){      
      this.presenceChannels[channelName] = window.Echo.join(channelName);
    }
    return this.presenceChannels[channelName];    
  }

  leveFromPresenceByChannelName(channelName){
    if(this.presenceChannels[channelName]){      
      window.Echo.leave(channelName);
      delete this.presenceChannels[channelName];
    }
  }

  getPresenceUsers() {
    return this.presenceChannel;
  }
}
