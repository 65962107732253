import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RequestService} from "../../http/request.service";
import {UtilityReading} from "@models/utility/utility-reading";
import {map} from "rxjs/operators";
import {Account} from "@models/user/account";
import {UtilityAccount} from "@models/utility/utility-account";

@Injectable({
  providedIn: 'root'
})
export class UtilityAccountService {
  apiPrefix = 'api/admin/utility-account/';

  constructor(private http: HttpClient,
              private request: RequestService) {
  }

  globalSearch(params) {
    return this.request.post(this.apiPrefix + 'global-search', params);
  }

  index() {
    return this.request.get(this.apiPrefix + 'index');
  }

  billIndex(params){
    return this.request.get(this.apiPrefix + 'bill/index', params);
  }

  save(params) {
    return this.request.post('api/admin/utility-account/save', params);
  }

  read(id) {
    return this.request.get('api/admin/utility-account/read/' + id).pipe(map(res => {
      return {...res.account, billing_issue_task: res.billing_issue_task} as UtilityAccount;
    }));
  }

  transactions(params) {
    return this.request.get('api/admin/supplier-account/bank-payments', params);
  }

  utilityAccountRead(id){
    return this.request.get('api/admin/utility-account/read/' + id).pipe(map(res => {
      return {account:{...res.account,billing_issue_task: res.billing_issue_task} , order:res.order}
    }));
  }

  utilityAccountFromEndpoint(params){
    return this.request.get('api/admin/utility-account/get-supplier-account-from-endpoint', params).pipe(map(res => {
      return {account:res.account as UtilityAccount}
    }));
  }

  addDocument(params) {
    return this.request.post(this.apiPrefix + 'add-docs/' + params.id, params).pipe(map(res => {
      return res;
    }));
  }

  deleteDocument(id) {
    return this.request.post(this.apiPrefix + 'delete-docs/' + id, {id}).pipe(map(res => {
      return res;
    }));
  }

  addUpdateCost(params){
    return this.request.post('api/admin/supplier-account-costs/save', params).pipe(map(res => {
      return res;
    }));
  }

  payBillDocuments(params) {
    return this.request.get('api/admin/utility-account/get-pay-bill-documents', params);
  }

  ordersList(params){
    return this.request.get('api/admin/order/list', params);
  }

  billValidationTaskSave(params){
    return this.request.post(`api/admin/utility-account/bill-validation/task/create-from-unallocated-doc`, params);
  }

  loadLoaParsedTemplate(params){
    return this.request.post(`api/admin/utility-account/loa/parse-template`, params);
  }

  sendLoAtoSupplier(params){
    return this.request.post(`api/admin/utility-account/loa/send-to-supplier`, params);
  }

  approveLoA(params){
    return this.request.post(`api/admin/utility-account/loa/mark-approved`, params);
  }
}
