import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  AbstractFormGroupControlValueAccessor,
  ngValidatorsProviderFactory,
  ngValueAccessorProviderFactory
} from "@shared/classes/AbstractFormGroupControlValueAccessor";
import {FormControl, Validators} from "@angular/forms";
import {ValidationService} from "@shared/components/error/validation.service";
import {Property} from "@models/property/property";
import {Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {AddressService} from "@shared-services/property/address.service";
import {PropertyService} from "@shared-services/property/property.service";
import {UtilityType} from "@models/utility/utility-type";

@Component({
  selector: 'app-property-search',
  templateUrl: './property-search.component.html',
  styleUrls: ['./property-search.component.scss'],
  providers: [
    ngValueAccessorProviderFactory(PropertySearchComponent),
    ngValidatorsProviderFactory(PropertySearchComponent)
  ],
})
export class PropertySearchComponent extends AbstractFormGroupControlValueAccessor implements OnInit{

  @Output() onPropertySelected: EventEmitter<Property> = new EventEmitter<Property>();
  private $filter: Subject<Number> = new Subject<Number>();
  form = this.fb.group({
    property: ['', Validators.required],
  });
  propertyOptions: Property[];

  ngOnInit() {
    let propertyService = this.AppInjector.get(PropertyService);
    this.$filter.pipe(
      debounceTime(200),
      switchMap(searchTerm => propertyService.list({filter: {search: searchTerm}}),
      )).subscribe(properties => {
      this.propertyOptions = properties;
    });
  }

  get property(): FormControl {
    return this.form.get('property') as FormControl;
  }

  onSearchProperty(event: any) {
    this.$filter.next(event.query);
  }

  onPropertyChanged() {
    this.onPropertySelected.emit(this.property.value as Property);
  }
}
