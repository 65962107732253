<div class="gantt-container" >
  <!-- header row-->
  <div class="row-axis" fxLayout="row">
    <div class="row-title ">
    </div>
    <div class="month-axis ">
            <span class="month" *ngFor="let month of monthAxis"
                  [style.width]="month.monthDurationPercentage + '%'" [ngClass]="{'current': month.monthName + ' ' + month.monthYear === currentMonth}"> {{month.monthName}} {{ month.monthYear}}</span>
    </div>
  </div>

  <app-utilify-gantt-row [rows]="rows" [chartPeriodDays]="chartPeriodDays" [monthAxis]="monthAxis" [startDate]="startDate"></app-utilify-gantt-row>
</div>
