import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileHelper} from "../../classes/FileHelper";

@Component({
	selector: 'app-horizontal-multi-select',
	templateUrl: './horizontal-multi-select.component.html',
	styleUrls: ['./horizontal-multi-select.component.scss']
})
export class HorizontalMultiSelectComponent implements OnInit {
	/*
	config: {
		iconColumn: string
		labelColumn: string,
		descColumn: string,
		enabledColumn: true|false,
	}
	 */

	selectedOption: any;
	@Input() config: any;
	@Input() options: Array<any>;
	@Output() selectedChange = new EventEmitter<any>();
	@Input() get selected() {
		return this.selectedOption;
	}
	@Output()
	change: EventEmitter<any> = new EventEmitter<any>();

	labelColumn: string;
	descColumn: string;
	iconColumn: string;
	enabledColumnExists: boolean;
	sizeMode: string; // small(default)|medium


	ngOnInit() {
		if (this.config.hasOwnProperty('labelColumn')) {
			this.labelColumn = this.config.labelColumn;
		} else {
			this.labelColumn = 'label';
		}
		if (this.config.hasOwnProperty('descColumn')) {
			this.descColumn = this.config.descColumn;
			this.sizeMode = 'medium';
		}
		if (this.config.hasOwnProperty('iconColumn')) {
			this.iconColumn = this.config.iconColumn;
		}
		if (this.config.hasOwnProperty('enabledColumn')) {
			this.enabledColumnExists = this.config.enabledColumn;
		}
	}

	set selected(val) {
		this.selectedOption = val;
		this.selectedChange.emit(this.selectedOption);
	}

	optionSelected(option: any) {
		this.selectedOption = option;
		this.change.emit(this.selectedOption);
	}

	iconPath(filename) {
		return FileHelper.basename(filename);
	}

}
