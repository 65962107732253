<div fxLayout="row" fxLayoutAlign="center start" class="reset-password-component" *ngIf="!loading">
	<div fxLayout="column" fxLayoutAlign="center center" fxFill>
		<div class="logo-container">
			<img src="{{ logoPath }}" alt="logo" class="logo"/>
		</div>
		<div class="panel panel--center panel--reset-password">
			<h3 class="text-left">Reset password</h3>

			<div *ngIf="tokenExpired">
				Unfortunately your password token has expired, please use the forgot password feature again to reset your password
				<div class="reset-password">
					<a routerLink="/forgot-password">Forgot password</a>
				</div>
			</div>

			<div *ngIf="!tokenExpired">

				<div *ngIf="!passwordReset">

					<form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" #ngForm="ngForm" [ngClass]="{'ng-submitted': formSubmitted}">
						<form-error-summary [formGroup]="resetPasswordForm" [serverSideErrors]="serverSideErrors" [submitted]="formSubmitted"></form-error-summary>

						<div class="input-group">
							<label for="password">New password</label>
							<input type="password" id="password" name="password" placeholder="Password (min 6 chars, one number)" formControlName="password" class="input input-m">
						</div>
						<div class="input-group">
							<label for="password2">Confirm password</label>
							<input type="password" id="password2" name="password2" placeholder="Confirm" formControlName="password_confirm" class="input input-m">
						</div>
						<button type="submit" class="button button-l button-full-width" [promiseBtn]="resetPasswordSubscription">Reset password</button>
					</form>
				</div>
			</div>

			<div *ngIf="passwordReset" class="password-reset-success">
				Your password has been successfully reset.
				<div class="login">
					<a routerLink="/login">Login</a>
				</div>
			</div>

		</div>
	</div>

