import { Injectable } from '@angular/core';
import { SocketService } from '@shared/services/socket.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  overlayIdSource = new BehaviorSubject<any>(null);
  overlayId$ = this.overlayIdSource.asObservable();

  constructor(
    private socketService: SocketService,
    private authService: AuthenticationService,
  ) { }

  logout() {
    this.socketService.leveFromPresence();
    return this.authService.logout();
  }
}
