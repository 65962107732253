import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, controlValue?: any) {
    controlValue = controlValue.charAt(0).toUpperCase() + controlValue.slice(1).replace(/_/g, ' ');
    let config = {
      'required': `${controlValue} required`,
      'invalidEmail': 'Invalid email address',
      'invalidPassword': 'Password must be at least 6 characters long, with atleast one uppercase, lowercase and contain a number.',
      'passwordMismatch': "Passwords don't match",
      'postcode': "Must be a valid UK postcode",
      'requiredPattern': `Invalid value`
      // 'minlength': `Minimum length ${controlValue.requiredLength}`
    };

    return config[validatorName];
  }

  static emailValidator(control: AbstractControl) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return {'invalidEmail': true};
    }
  }

  static passwordValidator(control: AbstractControl) {
    // min 6 chars with at least one number
    // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$
    if (control.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  static confirmPassword(control: FormControl, group: FormGroup, matchPassword: string) {
    if (group && group.controls[matchPassword].value === control.value) {
      return null;
    }
    return { 'passwordMismatch': true }
  }

  static postCodeValidator(control: FormControl)
  {
    let givenPostCode = control.value;
    let UKPostCodePattern = /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/;
    let isUKPostCodeValid = UKPostCodePattern.test(givenPostCode);
    if(!isUKPostCodeValid)
    {
      return { 'postcode': true }
    }
    return null;
  }
}
