import { Injectable } from '@angular/core';
import {RequestService} from '../../http/request.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiPrefix: string;

  constructor(private request: RequestService) {
    this.apiPrefix = 'api/admin/user/';
  }

  index(params) {
    return this.request.get(this.apiPrefix + 'index', params).pipe(map(res => {
      return res;
    }));
  }

  exportCsv(params) {
    return this.request.get(this.apiPrefix + 'report', params).pipe(map(res => {
      return res;
    }));
  }

  options(params) {
    return this.request.get(this.apiPrefix + 'options', params).pipe(map(res => {
      return res;
    }));
  }

  read(id) {
    return this.request.get(this.apiPrefix + 'read/' + id).pipe(map(res => {
      return res;
    }));
  }

  save(params) {
    return this.request.post(this.apiPrefix + 'save', params).pipe(map(res => {
      return res;
    }));
  }

  update(params) {
    return this.request.post(this.apiPrefix + 'profile/update', params).pipe(map(res => {
      return res;
    }));
  }

  remove(params) {
    return this.request.post(this.apiPrefix + 'remove', params).pipe(map(res => {
      return res;
    }));
  }

  invite(params) {
    return this.request.post(this.apiPrefix + 'invite', params).pipe(map(res => {
      return res;
    }));
  }

  usageReport(params)
  {
    return this.request.get(this.apiPrefix + 'usage-report', params);
  }

  saveCustomerAccount(params) {
    return this.request.post('api/admin/customer-account/save', params).pipe(map(res => {
      return res;
    }));
  }

  getCustomers(params){
    return this.request.get('api/admin/customer/options', params).pipe(map(res => {
      return res;
    }));
  }

  performanceStats(params) {
    return this.request.get('api/admin/tasks/stats/performance-report-by-user', params);
  }
}
