import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { filter, switchMap} from 'rxjs/operators';
import { User } from '@models/user/user';
import { of } from 'rxjs';
@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  @Input()
  set hasPermission(permission) {
    this.auth.currentUser.pipe(
      filter(res => {
        return typeof res !== 'undefined' && res !== null;
      }), switchMap((user: User) => {
        const userPermissions = user.permission_list;        
        return of(userPermissions.indexOf(permission) !== -1); 
      })).subscribe((hasPermission)=>{
        if(hasPermission){
          this.viewContainer.createEmbeddedView(this.templateRef);
        }else{
          this.viewContainer.clear();
        }
      })
  }
  constructor(private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AuthenticationService
    ) {}
}
