import {Injectable, isDevMode} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from "rxjs/operators";
import {AuthenticationService} from "../../authentication/authentication.service";
import {HttpError} from "./HttpErrors";
import {ToastrService} from "ngx-toastr";
import { CommonService } from "@shared/services/common.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private commonService: CommonService,
    ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const logFormat = 'background: red; color: white';
    return next.handle(request)
      .pipe(
        retry(0), // increase to try requests multiple times
        catchError((error: HttpErrorResponse) => {

          if (isDevMode()) {
            console.log('%c ' + error.status + ' Exception', logFormat);
          }

          if (error.status === HttpError.Unauthorized) { // unauthorized
            this.commonService.logout();
            throwError(error);
          }
          else if (error.status === HttpError.InternalServerError) {
            console.log(error);
            this.toastr.error(error.statusText);
          }
          else if (error.status === HttpError.BadRequest) {
            console.log(error);
            return throwError(error.error);
          }
          else if (error.status === HttpError.NotFound) {
            console.log('api endpoint not found');
          }else if(error.status === HttpError.Forbidden){
            return throwError({errors:[error.error.message]})
          }
          return throwError(error.error.message);
        })
      )
  }

}
