<div [formGroup]="form">
  <div class="input-group">
    <label class="required">Select a property</label>
    <p-autoComplete formControlName="property" [suggestions]="propertyOptions" dataKey="id" forceSelection="true" field="address.short_address" (onSelect)="onPropertyChanged()" (completeMethod)="onSearchProperty($event)" appendTo="body">
      <ng-template let-property pTemplate="item" class="item-row">
        <div class="property-address">{{ property.address.short_address }}</div>
        <div class="property-owner">{{ property?.owner?.display_name }} ({{ property?.owner?.contact_email }})</div>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
