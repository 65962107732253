import {Injectable} from '@angular/core';
import {Account} from '@models/user/account';
import {map} from 'rxjs/operators';
import {RequestService} from '../../http/request.service';
import {Month} from '@models/account/month';
import { AccountStatus } from '@models/user/account-status';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private request: RequestService) {
  }

  index(params) {
    return this.request.get('api/admin/customer-account/index-deprecated', params);
  }
  accountIndex(params) {
    return this.request.get('api/admin/customer-account/index', params);
  }

  read(id: number) {
    return this.request.get('api/admin/customer-account/read/' + id).pipe(map(res => {
      return res.account as Account;
    }));
  }

  save(params) {
    return this.request.post('api/admin/customer-account/save', params).pipe(map(res => {
      return res;
    }));
  }

  search(params?) {
    return this.request.get('api/admin/customer-account/index-deprecated', params).pipe(map(res => {
      return res.account_collection as Array<Account>;
    }));
  }

  globalSearch(params?) {
    return this.request.post('api/admin/customer-account/global-search', params);
  }

  options(params) {
    return this.request.get('api/admin/customer-account/options', params).pipe(map(res => {
      return res.account_options;
    }));
  }

  transactionHistory(params) {
    return this.request.get('api/admin/bank/transaction-history', params).pipe(map(res => {
      console.log(res);
    }));
  }

  paymentTransactions(params) {
    return this.request.get('api/admin/customer-account/payment-transactions', params);
  }

  balanceTransactions(params) {
    return this.request.get('api/admin/customer-account/balance-transactions', params);
  }

  accountHistory(params){
    return this.request.get('api/admin/payment-plan/statement-history/list-by-month',params);
  }

  balances(params) {
    return this.request.post('api/admin/customer-account/balances', params).pipe(map(res => {
      return res; // map
    }));
  }

  bankSummary(params) {
    return this.request.get('api/admin/bank/account/summary', params);
  }

  bankAccountOptions(params){
    return this.request.get('api/admin/bank/account/options', params);
  }


  history(params) {
    return this.request.get('api/customer/account/list-history', params).pipe(map(res => {
      res.month_collection = res.month_collection.map(monthInput => monthInput as Month);
      return res;
    }));
  }

  propertyGantt(params) {
    return this.request.get('api/admin/property/gantt-chart-data', params);
  }

  accountGantt(params) {
    return this.request.get('api/admin/customer-account/gantt-chart-data', params);
  }

  createCredit(params) {
    return this.request.post('api/admin/credit/create', params).pipe(map(res => {
      return res; // map
    }));
  }

  readFinanceSummary(){
    return this.request.get('api/admin/customer-account/finance-summary').pipe(map(res => {
      return res; // map
    }));
  }

  getAccountPayments(params){
    return this.request.get('api/admin/customer-account/payments',params).pipe(map(res => {
      return res;
    }));
  }

  getAccountTransactions(params){
    return this.request.get('api/admin/customer-account/transactions', params).pipe(map(res => {
      return res;
    }));
  }

  getAccountUsage(params){
    return this.request.get('api/admin/utility/usage/report/calculated-usage', params).pipe(map(res => {
      return res;
    }));
  }

  downloadTransactions(params) {
    return this.request.get('api/admin/customer-account/transactions/report', params);
  }

  customerAccountStatus(){
    return this.request.get('api/admin/customer-account/status/options').pipe(map(res => {
      return res.options as AccountStatus[]; // map
    }));
  }

  cancelAccount(params) {
    return this.request.post('api/admin/customer-account/cancel-orders', params);
  }
}
