import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from "primeng/api";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-copy-value',
  templateUrl: './copy-value.component.html',
  styleUrls: ['./copy-value.component.scss']
})
export class CopyValueComponent implements OnInit {

  @Input() value: any;
  @Input() centerAlign: boolean;

  constructor(private toastr: ToastrService) { }
  ngOnInit(): void {
  }

  confirmClick() {
    this.toastr.success('Copied: ' + this.value);
  }
}
