<page-loader [loading]="loading"></page-loader>
<div fxLayout="column" *ngIf="authData">
  <div class="barcode-container" fxLayout="row" fxLayoutAlign="center center" [innerHTML]="authData?.qr_code | keepHtml">
    <!-- <img [src]="authData?.qr_code" alt="QR Code"> -->
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span class="or" > OR </span>
  </div>
  <div class="serial-number-container" fxLayout="row" fxLayoutAlign="center center">
    <h4>{{authData?.secret}}</h4>
  </div>
  <div class="otp-input-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
    <input class="input input-xs input-currency" type="text" [(ngModel)]="code" placeholder="Enter OTP here">
    <span *ngIf="errorMessage" class="error">Invalid OTP</span>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="end center">
    <button type="submit" class="button button-s" (click)="submitOTP()">Submit</button>
  </div>
</div>
