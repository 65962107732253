import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {AuthenticationService} from "../../authentication/authentication.service";
import * as _ from "underscore";
import {User} from "../../models/user/user";
import {StorageMap} from "@ngx-pwa/local-storage";
import {filter, map, mergeMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private storage: StorageMap) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // We must make sure the user object is retrieved from local storage before we
    // try to amend the request to append the AuthToken header

    return fromPromise(this.authService.retrieveUserFromStorage$().toPromise()).pipe(
      mergeMap((user: User) => {
        if (user !== undefined) {
          const token = user.token;
          // Use the token in the request
            req = req.clone({
              headers: req.headers.set('AccessToken', `${token}`),
            });

        }
        req = req.clone({
          headers: req.headers.set('ngsw-bypass', '')
        });
        // Handle the request
        return next.handle(req);
        }));
  }
}
