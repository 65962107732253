import { Injectable } from '@angular/core';
import { RequestService } from '../../http/request.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private request: RequestService) { }


  getModuleFilters(params){
    return this.request.get(`api/admin/filters/by-model`,params);
  }

  getSavedFilters(params){
    return this.request.get(`api/admin/page-filters/index`,params);
  }

  saveModuleFilter(params){
    return this.request.post(`api/admin/page-filters/save`,params);
  }

  deleteFilter(params){
    return this.request.post(`api/admin/page-filters/delete`,params);
  }
}
