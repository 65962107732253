<page-loader [loading]="loading"></page-loader>
<div fxLayout="row" fxLayoutAlign="center start" class="activate-account-component" *ngIf="!loading">
	<div fxLayout="column" fxLayoutAlign="start center" fxFill>
		<div class="logo-container">
			<img src="{{ logoPath }}" alt="logo" class="logo"/>
		</div>
		<div class="panel panel--center panel--activate-account">

			<div class="errors" *ngIf="errors">
				<div *ngFor="let error of errors">
					{{ error }}
				</div>
			</div>

			<div *ngIf="user">
				<h3>Activate your {{ appName }} account</h3>

				<form [formGroup]="activateForm" (ngSubmit)="submit()" #ngForm="ngForm" [ngClass]="{'ng-submitted': formSubmitted}">
					<form-error-summary [formGroup]="activateForm" [serverSideErrors]="serverSideErrors" [submitted]="formSubmitted"></form-error-summary>
					<div class="input-group">
						<label for="password">Choose a password</label>
						<input type="password" id="password" name="password" placeholder="Password (min 6 chars, one number)" formControlName="password" class="input input-m">
					</div>
					<div class="input-group">
						<label for="password2">Confirm your password</label>
						<input type="password" id="password2" name="password2" placeholder="Confirm" formControlName="password_confirm" class="input input-m">
					</div>

					<button type="submit" class="button button-l button-full-width" [promiseBtn]="processing">Activate account</button>

				</form>
			</div>

		</div>


	</div>
</div>

