import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FilterService } from '@shared-services/filter/filter.service';
import { StorageHelperService } from '@shared-services/storage-helper.service';
import { ToastrService } from 'ngx-toastr';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.scss']
})
export class FiltersListComponent {
  @ViewChild('editFilterOverlay') editFilterOverlay: OverlayPanel; //buttonActionOverlay
  @ViewChild('buttonActionOverlay') buttonActionOverlay: OverlayPanel;
  @Input() filters:Array<any>;
  @Input() filterModule: string;
  @Input() savedFilters: Array<any>;
  @Input() filterStoragekey: string;
  @Input() pageFilterModule:string;
  @Output() onSelectFilter = new EventEmitter();
  @Output() onApplySavedFilter = new EventEmitter();
  @Output() onResetFilters = new EventEmitter();
  @Output() onRemoveFilter = new EventEmitter();
  @Output() dismissOverlay = new EventEmitter();
  @Output() savedFilterUpdate = new EventEmitter();
  @Output() savedFilterRemoved = new EventEmitter();
  @Output() resetDefaultFilters = new EventEmitter();
  selectedTab: number = 0;
  sidebarVisible: boolean;
  appliedFilters: Array<any> = [];
  saveEditFilter: boolean;
  publicFilters:Array<any>;
  privateFilters:Array<any>;
  selectedFilterForEdit:any;
  appliedSelectedFilter:any;  
  saveSubscription: Subscription;
  
  constructor(
    private filterService: FilterService, 
    private toastr: ToastrService,
    private storageHelper: StorageHelperService
    ){}
  ngOnInit(){
    
   
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['filters']?.currentValue){
      this.appliedFilters = changes['filters']?.currentValue.filter(f => f.implemented);
    }
    if(changes['savedFilters']?.currentValue){
      this.publicFilters = this.savedFilters.filter(f => f.is_public);
      this.privateFilters = this.savedFilters.filter(f => !f.is_public)
    }

    const filterId = this.storageHelper.get(this.filterStoragekey+'-selected-filter');
    if(filterId)
    this.appliedSelectedFilter = this.savedFilters.find(f => f.id == filterId);
    if(this.appliedSelectedFilter)
    this.appliedSelectedFilter.hasChanges = this.compareChanges();  
  }

  compareChanges(){
    if(this.filters.filter(f => f.implemented).length != Object.keys(this.appliedSelectedFilter.filter_criteria).length)
    return true;
    let hasChanges = false;
    this.filters.filter(f => f.implemented).forEach(f => {
        const selectedFilter = this.appliedSelectedFilter.filter_criteria[f.filterName];        
        if((f.filterDetail.type == 'key_value' || f.filterDetail.type == 'lookup')  && selectedFilter.value[f.filterDetail.extra.value] != f.value[f.filterDetail.extra.value]){
          hasChanges = true; 
        }else if(f.filterDetail.type == 'date'){
          if(selectedFilter.dateCategory && selectedFilter.dateCategory != f.dateCategory){            
            hasChanges = true;
          }
          
          if(selectedFilter.numberOfDays && selectedFilter.numberOfDays != f.numberOfDays){            
            hasChanges = true;
          }
          
          if(selectedFilter.value && selectedFilter.value != f.value){            
            hasChanges = true;
          }          
        }else if(f.filterDetail.type != 'key_value' && selectedFilter.value && selectedFilter.value != f.value){        
          hasChanges = true;
        }
        
    })
    return hasChanges;
  }

  updateFilter(){
    const filter = {}
    this.filters.filter(f => f.implemented).forEach(f => {
      filter[f.filterName] = {value:f.value};
      if(f.filterDetail.type == 'date'){
        if(f.numberOfDays)
        filter[f.filterName]['numberOfDays'] = f.numberOfDays;
        if(f.dateCategory)
        filter[f.filterName]['dateCategory'] = f.dateCategory;
      }
    })
    let payload = {
      ...this.appliedSelectedFilter,     
      filter_criteria:filter,
      page_filter:{id: this.appliedSelectedFilter.id}      
    }
   
    
      this.saveSubscription = this.filterService.saveModuleFilter(payload).subscribe(res => {         
        this.toastr.success('Filter updated successfully')       ;
        this.onSaveEditFilter(res.page_filter)
      });
    
  }

  addFilter(selectedFilter, event, filterIndex){
    this.onSelectFilter.emit({selectedFilter, event, filterIndex});
  }

  applySavedFilter(filter){
    this.onApplySavedFilter.emit(filter);
  }

  removeFilter(filter){
    this.onRemoveFilter.emit(filter);
  }

  resetFilters(){
    this.storageHelper.remove(this.filterStoragekey+'-selected-filter');
    this.onResetFilters.emit();
  }

  onSaveEditFilter(event){
    this.selectedFilterForEdit = null;
    this.saveEditFilter = false;   
    this.savedFilterUpdate.emit(event);
  }

  savedFilterAction(event, filter){
    if(this.editFilterOverlay.overlayVisible){
      this.editFilterOverlay.hide();
      return;
    }
    this.selectedFilterForEdit = filter;
    this.editFilterOverlay.show(event);    
  }

  deleteFilter(){       
    const filterId = this.selectedFilterForEdit.id;
    if(this.editFilterOverlay.overlayVisible)
    this.editFilterOverlay.hide();          
    this.filterService.deleteFilter({page_filter:{id:filterId}}).subscribe(res => {
      this.toastr.success('Filter removed successfully');
      this.savedFilterRemoved.emit(filterId);
    });
  }

  editFilter(){
    if(this.editFilterOverlay.overlayVisible)
    this.editFilterOverlay.hide();    
    this.saveEditFilter = true;
  }

  clickCreateFilter(){
    if(this.buttonActionOverlay.overlayVisible)
    this.buttonActionOverlay.hide();
    this.selectedFilterForEdit = null;
    this.saveEditFilter = true;
  }

  resetDefault(){
    this.resetDefaultFilters.emit()
  }
}
