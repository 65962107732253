import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, Form, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {ValidationService} from "./validation.service";

@Component({
  selector: 'form-error-summary',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent  {

  @Input() submitted: boolean;
  @Input() formGroup: FormGroup;
  @Input() serverSideErrors: Array<string>;
  constructor() {}

  get errors(): Array<string> {
    let errorArray = new Array<string>();
    if ((!this.formGroup.valid && this.formGroup.dirty) && this.submitted ) {
      Object.keys(this.formGroup.controls).forEach(key => {
        let controlErrorsCheck: ValidationErrors = this.formGroup.get(key).errors;
        let isChild = false;
        for (let key in controlErrorsCheck) {
          let obj = controlErrorsCheck[key];
          if (typeof(obj) == 'object') {
            isChild = true;
          }
        }
				const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
        if (controlErrors != null) {
          if (!isChild) {
            Object.keys(controlErrors).forEach(keyError => {
              errorArray.push(ValidationService.getValidatorErrorMessage(keyError, key));
            });
          }
          else {
            // deal with child form
            for (var key in controlErrors) {
              var obj = controlErrors[key];
              for (var prop in obj) {
                if (obj[prop]) {
                  errorArray.push(ValidationService.getValidatorErrorMessage(prop, key));
                }
              }
            }
          }
        }
      });
    }

    if (Array.isArray(this.serverSideErrors) && this.serverSideErrors.length) {
      this.serverSideErrors.forEach(error => {
        errorArray.push(error);
      });
    }

    return errorArray;
  }



}
