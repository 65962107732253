import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import {ngValidatorsProviderFactory, ngValueAccessorProviderFactory} from '@shared/classes/AbstractFormGroupControlValueAccessor';
import {AddressService} from '@shared-services/property/address.service';
import {Address} from '@models/shared/address';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

// describes what the return value of the form control will look like
export interface AddressFormValues {
  line_1: string;
  line_2: string;
  line_3: string;
  city: string;
  postcode: number;
  single_line_address: string;
}

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ngValueAccessorProviderFactory(AddressLookupComponent),
    ngValidatorsProviderFactory(AddressLookupComponent)
  ],
})
export class AddressLookupComponent implements OnInit {

  @ViewChild('searchTermInput', {static: true}) searchTermInput: ElementRef;
  @Input() parent: FormGroup;
  @Input() isAddressFromSC: boolean;
  @Input() address: any;
  @Input() serviceType:string;
  AppInjector: Injector;

  manualAddress = false;
  selectedAddress: Address;
  loading: boolean;
  subscriptions: Subscription[] = [];
  addresses: Array<any>;
  addressList: Array<any>;

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef, private toasr: ToastrService) {
    this.AppInjector = this.injector;
  }

  ngOnInit() {
    if (this.address) {
      this.parent.controls.postcode_entered.setValue(this.address.postcode);
      if (this.address) {
        this.searchClick();
      }
    }

    fromEvent(this.searchTermInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.addressList = this.addresses.filter(address => address.single_line_address.toLowerCase().match(text.toLowerCase()));
    });
  }

  get postcode_search(): FormControl {
    return this.parent.get('postcode_search') as FormControl;
  }

  get line_1(): FormControl {
    return this.parent.get('line_1') as FormControl;
  }

  get line_2(): FormControl {
    return this.parent.get('line_2') as FormControl;
  }

  get line_3(): FormControl {
    return this.parent.get('line_3') as FormControl;
  }

  get city(): FormControl {
    return this.parent.get('city') as FormControl;
  }

  get postcode(): FormControl {
    return this.parent.get('postcode') as FormControl;
  }

  get single_line_address(): FormControl {
    return this.parent.get('single_line_address') as FormControl;
  }

  get isValid() { return this.parent.controls.valid; }

  searchClick() {
    this.loading = true;
    const addressService = this.AppInjector.get(AddressService);
    const params = {postcode: this.parent.controls.postcode_entered.value};
    if (this.isAddressFromSC) {
      const request = this.serviceType == 'REBEL' ? addressService.lookupRebel(params) : addressService.lookupSC(params);
      request.subscribe(res => {
        if (res.addresses) {
          const addressData = res.addresses.map(address => {
            return this.getFormattedAddress(address);
          });
          this.addresses = addressData;
          this.addressList = addressData;
        } else {
          this.addresses = [];
          this.addressList = [];
          this.toasr.info(res.message);
        }
        this.loading = false;
        this.cdRef.markForCheck();
      }, error => {
        this.loading = false;
        this.toasr.error(error);
        this.cdRef.markForCheck();
        
      });
    } else {
      addressService.lookup(params).subscribe((addresses: Array<Address>) => {
        this.addresses = addresses;
        this.addressList = addresses;
        this.loading = false;
        this.cdRef.markForCheck();
      }, error => {
        this.loading = false;
        this.toasr.error(error);
        this.cdRef.markForCheck();     
      });
    }
  }

  selectedAddressClick(address: Address) {
    this.selectedAddress = address;
    this.parent.patchValue(address);
  }

  enterManualClick() {
    this.manualAddress = true;
    this.cdRef.detectChanges();
  }

  searchAddressClick() {
    this.manualAddress = false;
    this.cdRef.detectChanges();
  }

  getFormattedAddress(address) {
    const addressLineKeys = ['subBuildingName', 'buildingNumber', 'buildingName'];
    const addressKeys = ['subBuildingName', 'buildingNumber', 'buildingName', 'street', 'dependantStreet', 'locality', 'doubleDependantLocality', 'town', 'country', 'postcode'];
    const line1 = [];
    const singleLine = [];


    addressLineKeys.forEach(key => {
      if (address[key]) {
        line1.push(address[key]);
      }
    });
    if(this.serviceType == 'REBEL' && address.fullAddress)
      singleLine.push(address.fullAddress);
    else
      addressKeys.forEach(key => {
        if (address[key]) {
          singleLine.push(address[key]);
        }
      });
    

    const add = {
      line_1: line1.join(','),
      line_2: address.street,
      line_3: address.locality,
      city: address.town,
      country: address.county,
      postcode: address.postcode,
      multi_line_address: singleLine.join(','),
      single_line_address: singleLine.join(','),
      original_address_response:address
    };

    return add;
  }
}
