
<!--<page-loader [loading]="loading"></page-loader>-->


<div fxLayout="row" fxLayoutAlign="center start" class="login-component" *ngIf="!loading">
	<div fxLayout="column" fxLayoutAlign="start center" fxFill>
		<div class="logo-container">
			<img src="{{ logoPath }}" alt="logo" class="logo" />
		</div>
		<div class="panel panel--center panel--login">
			<h3 class="text-left">Admin Login</h3>

			<form [formGroup]="loginForm" (ngSubmit)="submit()" [ngClass]="{'ng-submitted': formSubmitted}">
				<form-error-summary [formGroup]="loginForm" [serverSideErrors]="serverSideErrors" [submitted]="formSubmitted"></form-error-summary>
				<div class="input-group" [hidden]="auth2FA">
					<label for="email">Email</label>
					<input type="text" id="email" name="email" placeholder="email" formControlName="email" class="input input-m">
				</div>

				<div class="input-group" [hidden]="auth2FA">
					<label for="password">Password</label>
					<input type="password" id="password" name="password" placeholder="password" formControlName="password" class="input input-m">
				</div>

        <div class="input-group" *ngIf="auth2FA">
          <label for="code">Code</label>
          <input type="text" id="code" name="code" placeholder="code" formControlName="code" class="input input-m" #codeInput>
        </div>
		<div class="remember-me" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="auth2FA">
			<p-checkbox [binary]="true" inputId="binary" formControlName="remember_me"></p-checkbox>
			<label for="binary">Remember me for {{twoFAExpiry}} days</label>
		</div>
        <button type="submit" class="button button-l button-full-width" [promiseBtn]="authenticateSubscription">Login</button>

      </form>

			<div class="forgot-password">
				<a routerLink="" (click)="forgotPassword()">Forgot Password?</a>
			</div>
		</div>
	</div>


</div>
