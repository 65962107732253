import {ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { environment} from "@environment";
import {fadeAnimation} from "../../animations/fade.animation";

@Component({
  selector: 'page-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
	animations: [fadeAnimation],
})
export class LoaderComponent implements OnInit, OnChanges {

	@Input() loading: boolean;
	@Input() topPaddingClass: string; // small|medium
  @Input() message: string;

	@HostBinding('class.hide') loaded: boolean = false;

	loadingGif: string;
  constructor(private cdRef:ChangeDetectorRef) {
		this.loadingGif = `assets/images/${environment.loadingGif}`;
		if (!this.topPaddingClass)
		{
			this.topPaddingClass = 'medium';
		}
	}

	ngOnChanges(changes: SimpleChanges) {
    if (changes.loading.currentValue === false) {
      this.loaded = true;
      this.cdRef.detectChanges();
    } else {
      this.loaded = false;
    }
  }

  ngOnInit(): void {
  }

}
