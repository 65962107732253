import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../../../../core/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidationService} from "../../../../shared/components/error/validation.service";
import {User} from "../../../../core/models/user/user";
import {environment} from "@environment";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  loading = true;
  token: string;
  resetPasswordForm: FormGroup;
  formSubmitted = false;
  serverSideErrors: Array<string>;
  tokenExpired = false;
  resetPasswordSubscription: Subscription;
  user: User;
  passwordReset = false;
  logoPath: string;
  appName: string;

  constructor(private authService: AuthenticationService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
		this.logoPath = `${environment.s3bucket}logos/logo.png`;
		this.appName = environment.appName;

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
			password_confirm: ['', [Validators.required, (control => ValidationService.confirmPassword(control, this.resetPasswordForm, 'password'))]],
    });

    this.route.params.subscribe(params => {
      if (params.token) {
        this.token = params.token;
        this.authService.validatePasswordToken(params.token).subscribe((user: User) => {
          this.user = user;
          this.loading = false;
        }, errors => {
          this.tokenExpired = true;
          this.loading = false;
        });
      }

    });
  }

  submit() {
    this.formSubmitted = false;
    if (!this.resetPasswordForm.valid) {
      this.resetPasswordForm.markAsDirty();
      this.formSubmitted = true;
    } else {
      const params = {
        password: this.resetPasswordForm.value.password,
        token: this.token,
      };
      this.resetPasswordSubscription = this.authService.resetPassword(params).subscribe((res) => {
        this.passwordReset = true;
      }, errors => {
        this.serverSideErrors = errors;
      });
    }
  }
}
