<div fxLayout="column" #filterListElement>
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="filter-heading" > Filters</span>
    <i class="fa-light fa-xmark" style="font-size: 16px;
    color: #8492A6;
    font-weight: bold;"></i>
  </div> -->
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="filter-heading"> 
        <ng-container *ngIf="saveEditFilter">
            <i class="fa-solid fa-chevron-left" (click)="saveEditFilter = false" style="cursor: pointer;margin-right: 10px;"></i>
        </ng-container>
        {{saveEditFilter ? selectedFilterForEdit ? 'Edit '+ selectedFilterForEdit.filter_name : 'Save active filters' : 'Filters'}}</span>
        <a *ngIf="!saveEditFilter" href="javascript:void(0)" class="default-filter" (click)="resetDefault()">Reset default</a>
    </div>
    <mat-tab-group #matTab [(selectedIndex)]="selectedTab" *ngIf="!saveEditFilter">
        <mat-tab label="All">
            <div fxLayout="column" fxLayoutGap="16px" style="margin-top: 16px;">
                <ng-container *ngFor="let activeFilter of appliedFilters">
                    <div class="active-filter">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="content">
                                <i class="filter-icon" [ngClass]="activeFilter?.filterDetail?.icon" *ngIf="activeFilter?.filterDetail?.icon"></i>
                                {{activeFilter.label}} :
                                <ng-container
                                  *ngIf="!(activeFilter?.value === undefined || activeFilter?.value === null || activeFilter?.value === '') || activeFilter?.numberOfDays; else anytemplate">
                                  <ng-container [ngSwitch]="activeFilter.filterDetail?.type">
                                    <span *ngSwitchCase="'string'">{{activeFilter?.value }}</span>
                                    <span *ngSwitchCase="'boolean'">{{activeFilter?.value.label}}</span>
                                    <!-- <span *ngSwitchCase="'date'">{{activeFilter?.value | date:'d MMM, y'}}</span> -->
                                    <span *ngSwitchCase="'date'">
                                      <ng-container [ngSwitch]="activeFilter.dateCategory">
                                        <span *ngSwitchCase="'between'">
                                          <ng-container *ngIf="activeFilter?.value">
                                            {{activeFilter?.value[0] | date:'d MMM, y'}} - {{ activeFilter?.value[1] ? (activeFilter?.value[1] | date:'d MMM, y') : ''}}
                                          </ng-container>
                                          <ng-container *ngIf="!activeFilter?.value">
                                            Any
                                          </ng-container>
                                        </span>
                                        <span *ngSwitchCase="'morethan'"> at more than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                        <span *ngSwitchCase="'lessthan'"> at less than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                        <span *ngSwitchCase="'exactly'"> at exactly {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                        <span *ngSwitchCase="'after'"> at after {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                        <span *ngSwitchCase="'before'"> at before {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                        <span *ngSwitchCase="'on'"> at on {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                        <span *ngSwitchDefault>{{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}}</span>
                  
                  
                                      </ng-container>
                                    </span>                  
                                    <!-- <span *ngSwitchCase="'lookup'">{{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}</span> -->
                                    <span *ngSwitchCase="'lookup'">
                                      <ng-container *ngIf="activeFilter?.filterDetail?.options !== 'supplier_accounts'">
                                        <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                                          <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                                            {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                                          </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                                          {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                                        </ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="activeFilter?.filterDetail?.options === 'supplier_accounts'">{{activeFilter?.value?.reference}} ({{activeFilter?.value?.supplier?.label}})</ng-container>
                                    </span>
                                    <span *ngSwitchCase="'key_value'">
                                      <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                                        {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                                      </ng-container>
                                      <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                                        <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                                          {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                                        </ng-container>
                                      </ng-container>
                                    </span>
                                    <span *ngSwitchDefault>
                                      {{activeFilter?.value || 'Any'}}
                                    </span>
                                  </ng-container>
                                </ng-container>
                                <ng-template #anytemplate>
                                  Any
                                </ng-template>
                  
                                <!-- {{!(activeFilter?.value === undefined || activeFilter?.value === null) ? activeFilter?.value?.label ||  activeFilter?.value : 'Any'}} -->
                            </div>
                            <i class="fa-regular fa-circle-xmark close-fa-icon" (click)="removeFilter(activeFilter)"></i>
                        </div>
                       
                      
                    </div>
                </ng-container>
            </div>
                <ng-container *ngIf="appliedFilters.length">
                  <!-- <div>
                    <button class="button button-s" style=" border-bottom-right-radius:0;border-top-right-radius:0;">Test</button>
                    <button class="button button-s" style=" border-top-left-radius: 0;border-bottom-left-radius: 0;width: 30px;min-width: 30px;"><i class="fa-solid fa-chevron-down"></i></button>                     
                    
                  </div> -->
                  
                  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 16px;" *ngIf="!appliedSelectedFilter || !appliedSelectedFilter.hasChanges">
                    <button class="button button-s no-border button-transparent" (click)="resetFilters()">Clear All</button>
                    <button class="button button-s"  (click)="selectedFilterForEdit = null; saveEditFilter = true">Save active filters</button>                    
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start" style="margin-top: 16px;" *ngIf="appliedSelectedFilter && appliedSelectedFilter.hasChanges">
                    <div fxLayout="row" style="width: 100%;" fxFlex="auto" fxLayoutAlign="space-between center">
                      <button fxFlex="auto" class="button button-s" [promiseBtn]="saveSubscription" (click)="updateFilter()">Update {{appliedSelectedFilter?.filter_name}}</button>
                      <button  fxFlex="auto" class="button button-s no-border button-transparent" (click)="selectedFilterForEdit = null; saveEditFilter = true">Create new</button>

                    </div>
                    <button class="button button-s no-border button-transparent" (click)="resetFilters()">Clear All</button>                    
                  </div> -->
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 16px;">
                        <button class="button button-s no-border button-transparent" (click)="resetFilters()">Clear All</button>
                        <button class="button button-s" *ngIf="!appliedSelectedFilter || !appliedSelectedFilter.hasChanges" (click)="selectedFilterForEdit = null; saveEditFilter = true">Save active filters</button>
                        <ng-container *ngIf="appliedSelectedFilter && appliedSelectedFilter.hasChanges">
                          <div fxLayout="row">                            
                            <button class="button button-s" (click)="updateFilter()" [promiseBtn]="saveSubscription" style="display: flex;border-bottom-right-radius:0;border-top-right-radius:0;">Update {{appliedSelectedFilter?.filter_name}}</button>
                            <button class="button button-s" (click)="buttonActionOverlay.toggle($event)" [disabled]="saveSubscription" style="border-top-left-radius: 0;border-bottom-left-radius: 0;width: 30px;min-width: 30px;"><i class="fa-solid fa-chevron-down"></i></button>                     
                          </div>
                          
                        </ng-container>
                        <!-- <button class="button button-s" [promiseBtn]="saveSubscription" *ngIf="appliedSelectedFilter && appliedSelectedFilter.hasChanges" (click)="updateFilter()">Update {{appliedSelectedFilter?.filter_name}}</button>                        -->
                    </div>
                    <p-divider></p-divider>
                </ng-container>
            <div fxLayout="column" fxLayoutGap="16px" style="max-height: 500px;
            overflow-y: auto;">
                <ng-container *ngFor="let filter of filters;let i=index">
                    <ng-container *ngIf="!filter.added">
                        <div class="filter-list" (click)="addFilter(filter, $event, i)"><i class="filter-icon" [ngClass]="filter?.filterDetail?.icon" *ngIf="filter?.filterDetail?.icon"></i> {{filter?.label}}</div>
                      </ng-container>
                </ng-container>
            </div>
        </mat-tab>
        <mat-tab [label]="'Public ('+publicFilters?.length+')'">          
          <div fxLayout="column" class="saved-filter-container" fxLayoutGap="8px">
            <ng-container *ngFor="let f of publicFilters">
              <div class="saved-filter-row" fxLayout="row" fxLayoutAlign="space-between center">
                <span fxFlex="auto" (click)="applySavedFilter(f)">{{f.filter_name}}</span>
                <i class="fa-solid fa-ellipsis" style="padding:10px 0 5px;color: #8492A6" (click)="savedFilterAction($event, f)"></i>
              </div>
            </ng-container>
            <ng-container *ngIf="!publicFilters?.length">
              <i>No public filters saved</i>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab [label]="'Private ('+ privateFilters?.length+')'">          
              <div fxLayout="column" class="saved-filter-container" fxLayoutGap="8px">
                <ng-container *ngFor="let f of privateFilters">
                  <div class="saved-filter-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxFlex="auto" (click)="applySavedFilter(f)">{{f.filter_name}}</span>
                    <i class="fa-solid fa-ellipsis" style="padding:10px 0 5px; color: #8492A6;" (click)="savedFilterAction($event,f)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!privateFilters?.length">
                  <i>No private filters saved</i>
                </ng-container>
              </div>
        </mat-tab>
      </mat-tab-group>
</div>
<app-save-filter *ngIf="saveEditFilter" [pageFilterModule]="pageFilterModule" [selectedFilter]="selectedFilterForEdit" [allFilters]="filters" [filters]="appliedFilters" [filterModule]="filterModule" (onSaveEditFilter)="onSaveEditFilter($event)"></app-save-filter>
<p-overlayPanel #editFilterOverlay styleClass="filter-edit-overlay">
  <div fxLayout="column">
    <div fxLayout="row" class="edit-filter-row" fxLayoutGap="10px" fxLayoutAlign="start center" (click)="editFilter()">
      <i class="fa-solid fa-pencil"></i> <span>Edit</span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="edit-filter-row" (click)="deleteFilter()">
      <i class="fa-solid fa-trash" ></i><span>Delete</span>
    </div>
  </div>
</p-overlayPanel>
<p-overlayPanel #buttonActionOverlay styleClass="filter-edit-overlay">
  <div fxLayout="column">
    <div fxLayout="row" class="edit-filter-row" fxLayoutGap="10px" fxLayoutAlign="start center" (click)="editFilter()">
      <span (click)="clickCreateFilter()">Create new filter</span>
    </div>  
  </div>
</p-overlayPanel>




