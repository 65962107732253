<main fxLayout="column" fxFlex="auto" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''" class="main">
	<router-outlet #o="outlet"></router-outlet>
</main>
<!-- <p-confirmDialog header="Confirmation" key="confirmVersionUpdate" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->
<p-toast position="top-right" key="confirmVersionUpdate">
	<ng-template let-message pTemplate="message">
		<div class="flex flex-column" style="flex: 1">
			<div class="text-center">
				<h4>New version available. Load New Version?</h4>				
			</div>
			<div fxLayout="row" fxLayoutGap="10px">
				<button fxFlex="auto" type="button" pButton label="No" class="p-button-secondary"></button>
				<button fxFlex="auto" type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
			</div>
		</div>
	</ng-template>
	</p-toast>

