import {Component, Input, OnInit} from '@angular/core';
import {UsageStatement} from "../../../core/models/account/usage-statement";
import {FileService} from "../../../core/services/file/file.service";
import {environment} from "@environment";

@Component({
	selector: 'app-usage-statement-link',
	templateUrl: './usage-statement-link.component.html',
	styleUrls: ['./usage-statement-link.component.scss']
})
export class UsageStatementLinkComponent implements OnInit {

	loading: boolean;

	@Input() statement: UsageStatement;

	constructor(private fileService: FileService) {
	}

	ngOnInit(): void {
		this.loading = false;
	}

	download() {
		this.loading = true;
		this.fileService.download(this.statement.file_id).subscribe((downloadKey: string) => {
			this.loading = false;
			window.open(environment.baseUrl + 'file/download/' + downloadKey, '_blank');
		});
	}
}
