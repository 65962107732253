<div fxLayout="row" fxLayoutAlign="none center">
	<div fxFlex="none" fxLayout="column" fxLayoutAlign="center center" class="icon-container" (click)="decrement()">
		<div fxLayout="row" fxLayoutAlign="center center">
			<mat-icon svgIcon="minus-solid"></mat-icon>
		</div>
	</div>
	<div fxFlex="none" fxLayout="column" fxLayoutAlign="center center" class="quantity-value-container">
		<div fxFlex="auto" class="quantity-value">{{ roomCount }}</div>
		<div fxFlex="auto" class="quantity-title">Beds</div>
	</div>
	<div fxFlex="none" fxLayout="column" fxLayoutAlign="center center" class="icon-container" (click)="increment()">
		<div fxLayout="row" fxLayoutAlign="center center">
			<mat-icon svgIcon="plus-solid"></mat-icon>
		</div>
	</div>
</div>
