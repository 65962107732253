import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgMoveNextOnMaxLengthDirective} from "./directives/ng-move-next/ng-move-next-on-max-length.directive";
import {ErrorComponent} from "./components/error/error.component";
import {AutofocusDirective} from "./directives/auto-focus/auto-focus.directive";
import {AddressFormComponent} from './components/form-components/address-form/address-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LoaderComponent} from './components/loader/loader.component';
import {ExtendedModule, FlexModule} from "@angular/flex-layout";
import {HorizontalMultiSelectComponent} from './components/horizontal-multi-select/horizontal-multi-select.component';
import {MatIconModule} from "@angular/material/icon";
import {AddressLookupComponent} from './components/form-components/address-lookup/address-lookup.component';
import {RouterModule} from "@angular/router";
import {QuantitySelectorComponent} from './components/quantity-selector/quantity-selector.component';
import {StepperStepsComponent} from './components/stepper-steps/stepper-steps.component';
import {StepperVisiblePipe} from "./pipes/StepperVisiblePipe";
import { UsageStatementLinkComponent } from './components/usage-statement-link/usage-statement-link.component';
import { CustomerSearchComponent } from './components/form-components/customer-search/customer-search.component';
import {OverlayModule} from "@angular/cdk/overlay";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { InputFilterPipe } from './pipes/input-filter.pipe';
import {EscapeHtmlPipe} from "@shared/directives/keep-html/keep-html.directive";

// Directives
import { ScrollTrackerDirective } from './directives/scroll-tracking/scroll-tracking.directive';
import { UtilifyGanttComponent } from './components/utilify-gantt/utilify-gantt.component';
import { UtilifyGanttRowComponent } from './components/utilify-gantt/utilify-gantt-row/utilify-gantt-row.component';
import { PropertySearchComponent } from './components/form-components/property-search/property-search.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import { StatusPreviewComponent } from './components/status-preview/status-preview.component';
import { TwoStepVerificationComponent } from './components/two-step-verification/two-step-verification.component';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { EditorModule } from 'primeng/editor';
import { TooltipModule } from 'primeng/tooltip';
import { CopyValueComponent } from './directives/copy-value/copy-value.component';
import {ClipboardModule} from "ngx-clipboard";
import {ToastModule} from "primeng/toast";
import { HasPermissionDirective } from './directives/has-perimission.directive';
import { LoadingBtnDirective } from './directives/loading-btn/loading-btn.directive';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { HighlightEventDirective } from './directives/highlight-event/highlight-event.directive';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { FiltersComponent } from './components/filters/filters/filters.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SearchFilterComponent } from './components/filters/search-filter/search-filter.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FiltersListComponent } from './components/filters/filters-list/filters-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { SaveFilterComponent } from './components/filters/save-filter/save-filter.component';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    NgMoveNextOnMaxLengthDirective,
    ErrorComponent,
    AutofocusDirective,
    AddressFormComponent,
    LoaderComponent,
    HorizontalMultiSelectComponent,
    AddressLookupComponent,
    QuantitySelectorComponent,
    StepperStepsComponent,
    StepperVisiblePipe,
    UsageStatementLinkComponent,
    CustomerSearchComponent,
    DateAgoPipe,
    InputFilterPipe,
    ScrollTrackerDirective,
    EscapeHtmlPipe,
    UtilifyGanttComponent,
    UtilifyGanttRowComponent,
    PropertySearchComponent,
    StatusPreviewComponent,
    TwoStepVerificationComponent,
    QuillEditorComponent,
    CopyValueComponent,
    HasPermissionDirective,
    LoadingBtnDirective,
    NameInitialsPipe,
    HighlightEventDirective,
    RelativeTimePipe,
    FiltersComponent,
    SearchFilterComponent,
    FiltersListComponent,
    SaveFilterComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexModule,
        MatIconModule,        
        RouterModule,
        ExtendedModule,
        OverlayModule,
        MatProgressSpinnerModule,
        AutoCompleteModule,
        FormsModule,
        EditorModule,
        TooltipModule,
        ClipboardModule,
        ToastModule,
        OverlayPanelModule,
        DropdownModule,
        ChipModule,
        CalendarModule,
        MultiSelectModule,
        RadioButtonModule,
        MatTabsModule,
        DividerModule,
        SidebarModule,
        InputSwitchModule
    ],
    exports: [
        NgMoveNextOnMaxLengthDirective,
        ErrorComponent,
        AutofocusDirective,
        AddressFormComponent,
        LoaderComponent,
        HorizontalMultiSelectComponent,
        AddressLookupComponent,
        QuantitySelectorComponent,
        StepperStepsComponent,
        StepperVisiblePipe,
        UsageStatementLinkComponent,
        CustomerSearchComponent,
        DateAgoPipe,
        InputFilterPipe,
        ScrollTrackerDirective,
        EscapeHtmlPipe,
        UtilifyGanttComponent,
        PropertySearchComponent,
        StatusPreviewComponent,
        QuillEditorComponent,
        CopyValueComponent,
        HasPermissionDirective,
        LoadingBtnDirective,
        NameInitialsPipe,
        HighlightEventDirective,
        RelativeTimePipe,
        FiltersComponent
    ],
  providers: [DateAgoPipe]
})
export class SharedModule {
}
