import { Directive, Input, ElementRef } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[promiseBtn]'
})
export class LoadingBtnDirective {
  promise: any;
  btnEl: any;
  isPromiseDone: boolean;
  _fakePromiseResolve:any;
  cfg = {
    spinnerTpl: '<span class="btn-spinner"></span>',
    disableBtn: true,
    btnLoadingClass: 'is-loading',
    handleCurrentBtnOnly: false,
    minDuration: null,
  }
  @Input() set promiseBtn(passedValue) {
    const isObservable = passedValue instanceof Observable;
    const isSubscription = passedValue instanceof Subscription;
    const isBoolean = typeof passedValue === 'boolean';
    const isPromise = passedValue instanceof Promise || (passedValue !== null &&
      typeof passedValue === 'object' &&
      typeof passedValue.then === 'function' &&
      typeof passedValue.catch === 'function');
    if (isObservable) {
      throw new TypeError('promiseBtn must be an instance of Subscription, instance of Observable given');
    }
    else if (isSubscription) {
      const sub = passedValue;
      if (!sub.closed) {
        this.promise = new Promise((resolve) => {
          sub.add(resolve);
        });
      }
    }
    else if (isPromise) {
      this.promise = passedValue;
    }
    else if (isBoolean) {
      this.promise = this.createPromiseFromBoolean(passedValue);
    }
    this.checkAndInitPromiseHandler(this.btnEl);
  };
  constructor(elementRef: ElementRef) {
    this.btnEl = elementRef.nativeElement;
  }

  ngAfterContentInit() {
    this.prepareBtnEl(this.btnEl);
    // trigger changes once to handle initial promises
    this.checkAndInitPromiseHandler(this.btnEl);
  }

  initLoadingState(btnEl) {
    this.addLoadingClass(btnEl);
    this.disableBtn(btnEl);
  }

  disableBtn(btnEl) {
    if (this.cfg.disableBtn) {
      btnEl.setAttribute('disabled', 'disabled');
    }
  }



  addLoadingClass(el) {
    //if (typeof this.cfg.btnLoadingClass === 'string') {
    el.classList.add(this.cfg.btnLoadingClass);
    //}
  }

  checkAndInitPromiseHandler(btnEl) {
    // check if element and promise is set
    if (btnEl && this.promise) {
      this.initPromiseHandler(btnEl);
    }
  }

  initPromiseHandler(btnEl) {
    const promise = this.promise;
    // watch promise to resolve or fail

    this.isPromiseDone = false;
    // create timeout if option is set

    const resolveLoadingState = () => {
      this.isPromiseDone = true;
      this.cancelLoadingStateIfPromiseAndMinDurationDone(btnEl);
    };
    if (!this.cfg.handleCurrentBtnOnly) {
      this.initLoadingState(btnEl);
    }
    // native Promise doesn't have finally
    if (promise.finally) {
      promise.finally(resolveLoadingState);
    }
    else {
      promise
        .then(resolveLoadingState)
        .catch(resolveLoadingState);
    }
  }

  cancelLoadingStateIfPromiseAndMinDurationDone(btnEl) {
    if (this.isPromiseDone) {
      this.removeLoadingClass(btnEl);
      this.enableBtn(btnEl);
    }
  }

  enableBtn(btnEl) {
    if (this.cfg.disableBtn) {
      btnEl.removeAttribute('disabled');
    }
  }

  removeLoadingClass(el) {
    //if (typeof this.cfg.btnLoadingClass === 'string') {
    el.classList.remove('is-loading');
    //}
  }

  prepareBtnEl(btnEl) {
    // handle promises passed via promiseBtn attribute
    this.appendSpinnerTpl(btnEl);
  }


  appendSpinnerTpl(btnEl) {
    // TODO add some kind of compilation later on
    btnEl.insertAdjacentHTML('beforeend', this.cfg.spinnerTpl);
  }




  createPromiseFromBoolean(val) {
    if (val) {
        return new Promise((resolve) => {
            this._fakePromiseResolve = resolve;
        });
    }
    else {
        if (this._fakePromiseResolve) {
            this._fakePromiseResolve();
        }
        return this.promise;
    }
}



}
