
import { Component, Input, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { Supplier } from '@models/shared/supplier';
import { OverlayPanel } from 'primeng/overlaypanel';
import { debounceTime, switchMap, map, distinctUntilChanged, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { SupplierService } from '@shared-services/supplier/supplier.service';
import { UserService } from '@shared-services/user/user.service';
import dayjs from "dayjs";
import { FilterService } from '@shared-services/filter/filter.service';
import { AccountService } from '@shared-services/account/account.service';
import { StorageHelperService } from '@shared-services/storage-helper.service';
import { UtilityAccountService } from '@shared-services/utility/utility-account.service';
import { UtilityAccount } from '@models/utility/utility-account';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @ViewChild('filterOverlay', { static: false }) filterOverlay: OverlayPanel;
  @ViewChild('filterListOvarlay', { static: false }) filterListOvarlay: OverlayPanel;
  @ViewChild('searchTermInput', { static: false }) searchTermInput: ElementRef;
  @Input() customerOrderType: string;
  @Output() moduleFiltersLoaded = new EventEmitter<boolean>();
  searchTerm: string;
  supplierOptions: Supplier[];
  supplier: Supplier;
  appliedFilterChips: { key: string, value: string }[];
  allFilters: Array<any> = [];
  activeFilters = [];
  currentFilter: any;
  systemUserFilter$: Subject<any> = new Subject();
  customerUserFilter$: Subject<any> = new Subject();
  customerAccountFilter$: Subject<any> = new Subject();
  supplierAccountFilter$: Subject<any> = new Subject();
  supplierFilter$: Subject<any> = new Subject();
  booleanOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
  isSearch: boolean;
  hasActiveFilters: boolean;
  @Input() filterModule: string;
  @Input() filterStoragekey: string;
  @Output() onFilterChanged = new EventEmitter();
  @Input() pageFilterModule: string;
  currentFilters: any;
  savedFilters:Array<any>;
  activeFilters$:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  listVisible: boolean = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    private supplierService: SupplierService,
    private userService: UserService,
    private filterService: FilterService,
    private accountService: AccountService,
    private storageHelper: StorageHelperService,
    private utilityAccountService: UtilityAccountService
  ) {


    this.systemUserFilter$.pipe(
      debounceTime(200),
      switchMap(searchTerm => this.userService.index({ filter: { search: searchTerm, system_user: true } }),
      )).subscribe(usersRes => {
        this.currentFilter.suggestions = usersRes.user_collection;
        this.cdRef.markForCheck();
      });

    this.customerUserFilter$.pipe(
      debounceTime(200),
      switchMap(searchTerm => this.userService.index({ filter: { search: searchTerm, customer: true } }),
      )).subscribe(usersRes => {
        this.currentFilter.suggestions = usersRes.user_collection;
        this.cdRef.markForCheck();
      });

    this.customerAccountFilter$.pipe(
      debounceTime(200),
      switchMap(searchTerm => this.accountService.options({ filter: { search: searchTerm } }),
      )).subscribe(ownerOptions => {
        this.currentFilter.suggestions = ownerOptions;
        this.cdRef.markForCheck();
      });

    this.supplierAccountFilter$.pipe(
      debounceTime(200),
      switchMap(searchTerm => this.utilityAccountService.globalSearch({ filter: { search: searchTerm } }),
      )).subscribe(accountRes => {
        // this.currentFilter.suggestions = accountRes.account_collection.map(account => account.supplier);
        this.currentFilter.suggestions = accountRes.account_collection;
        this.cdRef.markForCheck();
      });

     this.supplierFilter$.pipe(
      debounceTime(200),
      switchMap(searchTerm => this.supplierService.options({ filter: { search: searchTerm } }),
      )).subscribe(supplier => {
        this.currentFilter.suggestions = supplier.supplier_options;
        this.cdRef.markForCheck();
      });


  }

  showListOverlay(event){
    if(this.filterListOvarlay.overlayVisible){
      this.filterListOvarlay.hide();      
      return;      
    }
    this.listVisible = true;
    this.filterListOvarlay.show(event);
    
  }

  ngAfterViewInit(){
    this.filterOverlay.onShow
    .subscribe(()=>{     
      this.setInputFocus();      
    })
    this.filterListOvarlay.onHide.subscribe(()=>{
      this.listVisible = false;
    })
  }


  setInputFocus(){
    setTimeout(() => {
      if(this.filterOverlay)
      Array.from(this.filterOverlay.container.children).forEach(el => {
        const input = <HTMLElement>el.querySelector('.input-s');
        const autoComplete = <HTMLElement>el.querySelector('.p-autocomplete-input');
        const autoCompleteMultipleChoice = <HTMLElement>el.querySelector('input[type="text"]')
        if(input)
        input.focus();
        else if(autoComplete)
        autoComplete.focus();
        else if(autoCompleteMultipleChoice)
        autoCompleteMultipleChoice.focus();
      });
    }, 30);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
}

  ngOnInit() {
    const activeFilters = this.storageHelper.get(this.filterStoragekey);
    if(activeFilters && activeFilters.expiry > new Date().getTime()){
      this.isSearch = activeFilters?.isSearch;
      this.allFilters = activeFilters?.allFilters || [];
    }else{
      this.storageHelper.remove(this.filterStoragekey);
      this.allFilters = [];
    }
   
    this.activeFilters = this.allFilters.filter(filter => {
      if (filter.filterDetail.type == 'date' && filter.value){
        if(filter.dateCategory != 'between')
        filter.value = new Date(filter.value)
        else
        filter.value = [new Date(filter.value[0]), new Date(filter.value[1])]
      }
        
      return filter.implemented;
    });
    this.hasActiveFilters = !!this.allFilters.filter(filter => filter.implemented).length
    this.applyFilter();
    let payload = {
      model: this.filterModule
    }
    if(this.customerOrderType)
    payload['for'] = this.customerOrderType;
    
    forkJoin({
      moduleFilters: this.loadModuleFilters(),
      savedFilters: this.filterService.getSavedFilters({module:this.pageFilterModule})
    }).subscribe((res)=>{
      this.isSearch = res.moduleFilters.filters?.search;
      Object.keys(res.moduleFilters.filters).forEach(filterKey => {
        if (filterKey !== 'search' && this.allFilters.findIndex(filter => filter.filterName == filterKey) === -1) {
          this.allFilters.push({
            filterName: filterKey,
            applied: false,
            added: false,
            label: res.moduleFilters.filters[filterKey].label,
            filterDetail: { ...res.moduleFilters.filters[filterKey], ...(res.moduleFilters.filters[filterKey].min && {min: dayjs(res.moduleFilters.filters[filterKey].min).toDate()}) , ...(res.moduleFilters.filters[filterKey].max && {max: dayjs(res.moduleFilters.filters[filterKey].max).toDate()}) }
          })
        }else{
          let filterIndex = this.allFilters.findIndex(filter => filter.filterName == filterKey)
          if(filterIndex !== -1){
            this.allFilters[filterIndex].label = res.moduleFilters.filters[filterKey].label;
            this.allFilters[filterIndex].filterDetail = { ...res.moduleFilters.filters[filterKey], ...(res.moduleFilters.filters[filterKey].min && {min: dayjs(res.moduleFilters.filters[filterKey].min).toDate()}) , ...(res.moduleFilters.filters[filterKey].max && {max: dayjs(res.moduleFilters.filters[filterKey].max).toDate()}) }
          }          
        }

      });
      this.savedFilters = res.savedFilters.page_filters;
      this.moduleFiltersLoaded.emit(true);
      this.cdRef.markForCheck();
    });
  }


  loadModuleFilters(){
    let payload = {
      model: this.filterModule
    }
    if(this.customerOrderType)
    payload['for'] = this.customerOrderType;
    return this.filterService.getModuleFilters(payload);
  }

 

  onAddFilter(event){
    this.addFilter(event.selectedFilter, event.event, event.filterIndex)
  }

  addFilter(selectedFilter, event, filterIndex) {
    if (this.filterListOvarlay.overlayVisible)
      this.filterListOvarlay.hide();
    this.allFilters.forEach((filter) => {
      if (filter.added && !filter.implemented)
        filter.added = false;
    })
    selectedFilter.added = true;

    this.activeFilters = this.activeFilters.filter(filter => filter.implemented);
    this.activeFilters.push(selectedFilter);
    this.currentFilter = selectedFilter;
    setTimeout(() => {
      let htmlElement = document.getElementById(selectedFilter.filterName);
      this.filterOverlay.show(event, htmlElement);
    }, 100);    
    this.allFilters = [].concat(this.allFilters)

  }

  onApplySavedFilters(selectedFilter){
    this.storageHelper.set(this.filterStoragekey+'-selected-filter',selectedFilter.id)
    if (this.filterListOvarlay.overlayVisible)
      this.filterListOvarlay.hide();    
    const selectedFilterCriteria = selectedFilter.filter_criteria;
    if(selectedFilterCriteria){
      this.allFilters.forEach(af => {
        if(!selectedFilterCriteria[af.filterName]){
          af.implemented = false;                    
          af.value = undefined;
          af.added = false;
          return;
        }
        af.implemented = true;
        af.added = true;
        if(af.filterDetail.type == 'key_value'){
          af.value = selectedFilterCriteria[af.filterName].value               
        }
        if(af.filterDetail.type == 'string'){
            af.value = selectedFilterCriteria[af.filterName].value;
        }

        if(af.filterDetail.type == 'lookup'){
          af.value = selectedFilterCriteria[af.filterName].value;
        }

        if(af.filterDetail.type == 'date'){
          //af = {...af, ...selectedFilterCriteria[af.filterName]}
          af = Object.assign(af, selectedFilterCriteria[af.filterName]);          
          
        }      
      })      
    }    
     this.applyFilter()
  }

  showFilterOverlay(event, filter) {    
    this.hideFilterOverlay();
    setTimeout(() => {
      this.currentFilter = filter;
      this.filterOverlay.show(event);      
    }, 30);
  }

  onSearchUpdate(searchText) {
    this.searchTerm = searchText;
    this.applyFilter();
  }


  ngOnChanges(changes) { }
  resetFilters() {
    if(this.filterListOvarlay.overlayVisible)
    this.filterListOvarlay.hide();
    this.allFilters.forEach(filter => {
      filter.added = false;
      filter.implemented = false;
      filter.value = undefined;
    })
    this.activeFilters = [];
    this.applyFilter();
  }

  savedFilterRemoved(filterId){
    this.savedFilters = this.savedFilters.filter(f => f.id !== filterId);
  }

  savedFilterUpdate(event){
     const fitlerIndex = this.savedFilters.findIndex(f => f.id == event.id)
    if(fitlerIndex !== -1){
      this.savedFilters[fitlerIndex] = event;
    }
    else{
      this.savedFilters.push(event);
    }
    if(this.filterListOvarlay.overlayVisible)
    this.filterListOvarlay.hide();
    this.savedFilters = [].concat(this.savedFilters)
    this.cdRef.markForCheck();
  }

  onSelectLookupOption() {
    const filter = this.allFilters.find(filter => filter.filterName == this.currentFilter.filterName);
    filter.implemented = true;
    this.applyFilter();    
    if(!this.currentFilter.filterDetail.is_multiple)
    this.hideFilterOverlay();
  }

  onLookup(e) {
    if (this.currentFilter.filterDetail.options === 'system_users')
      this.systemUserFilter$.next(e.query);

    if (this.currentFilter.filterDetail.options === 'customer_users')
      this.customerUserFilter$.next(e.query);

    if (this.currentFilter.filterDetail.options === 'customer_accounts')
      this.customerAccountFilter$.next(e.query);

    if (this.currentFilter.filterDetail.options === 'supplier_accounts')
      this.supplierAccountFilter$.next(e.query);

    if (this.currentFilter.filterDetail.options === 'suppliers')
      this.supplierFilter$.next(e.query);
  }

  myAccountConversionMethod(account: UtilityAccount){
    return `${account?.reference} (${account?.supplier?.label})`;
  }

  onClearLookup(event) {    
    setTimeout(() => {
      if (!event.target.value)
        this.applyFilter();
    }, 100);
  }

  onChangeText(e) { }

  onSearchClick() {
    this.currentFilter.value = this.currentFilter.searchText;
    this.currentFilter.searchText = '';
    this.currentFilter.implemented = true;
    this.hideFilterOverlay();
    this.applyFilter();
  }

  cancelSearch() {
    this.currentFilter.searchText = '';
    this.hideFilterOverlay();
  }

  onRemoveFilter(currentFilter) {
    this.activeFilters = this.activeFilters.filter((filter) => filter.filterName !== currentFilter.filterName);
    this.allFilters.find(filter => filter.filterName === currentFilter.filterName).added = false;
    this.allFilters.find(filter => filter.filterName === currentFilter.filterName).implemented = false;
    this.allFilters.find(filter => filter.filterName === currentFilter.filterName).value = undefined;
    delete this.allFilters.find(filter => filter.filterName === currentFilter.filterName).numberOfDays;
    delete this.allFilters.find(filter => filter.filterName === currentFilter.filterName).dateCategory;

    setTimeout(() => {
     this.hideFilterOverlay();
    }, 100);
    this.applyFilter();
  }

  onSelectDropdownOption() {
    this.currentFilter.implemented = true;
    this.applyFilter();
    this.hideFilterOverlay();
  }

  hideFilterOverlay(){ 
      if(this.filterOverlay.overlayVisible)
      this.filterOverlay.hide();
      this.currentFilter = null;
  }

  onSelectMultiOption($event) {
    this.currentFilter.implemented = true;
    this.applyFilter();
  }

  onSelectDate(event) {
    if(this.currentFilter.dateCategory === 'between' && this.currentFilter.value.length && !this.currentFilter.value[1]){      
      return;
    }
    this.currentFilter.implemented = true;
    this.hideFilterOverlay();
    this.applyFilter();
  }

  applyFilter() {
    const filerPayload = {};
    this.allFilters.forEach(filter => {
      if (!filter.implemented)
        return;
      const filterKey = filter.filterName;
      let filterValue = undefined;

      switch (filter.filterDetail.type) {
        case 'string':
          filterValue = filter.value;
          break;
        case 'key_value':
          if (filter.filterDetail.is_multiple) {
            filterValue = filter.value.map(v => v[filter.filterDetail.extra.value])
          } else {
            filterValue = filter.value[filter.filterDetail.extra.value]
          }
          break;
        case 'date':
          filterValue = this.getDateValueAndOperator(filter)
          break;
        case 'boolean':
          filterValue = filter.value.value;
          break;
        case 'lookup':
          if (!filter.value) {
            filter.implemented = false;
            return;
          }
          if (filter.filterDetail.is_multiple) {
            filterValue = filter.value.map(v => v[filter.filterDetail.extra.value])
          } else {
            filterValue = filter.value[filter.filterDetail.extra.value]
          }

          // filterValue = filter.value[filter.filterDetail.extra.value];
          break;
        default:
          filterValue = filter.value;
      }
      filerPayload[filterKey] = filterValue;
    })
    this.allFilters = [].concat(this.allFilters)
    if (this.searchTerm)
      filerPayload['search'] = this.searchTerm;
    this.hasActiveFilters = !!this.allFilters.filter(filter => filter.implemented).length
    this.saveToLocalStorage();
    if (JSON.stringify(this.currentFilters) !== JSON.stringify(filerPayload)) {
      this.currentFilters = filerPayload;
      this.onFilterChanged.emit(filerPayload);
      this.activeFilters$.next(Object.keys(filerPayload).filter((key)=> key !== 'search'));
    }
  }

  getDateValueAndOperator(filter){
    let data = {};   
    switch(filter.dateCategory){
      case 'after':
        data['value'] = dayjs(filter.value).format('YYYY-MM-DD');
        data['operator'] = '>';
        break;
      case 'before':
        data['value'] = dayjs(filter.value).format('YYYY-MM-DD');
        data['operator'] = '<';
        break;
      case 'between':
        if(!filter.value)
        return;
        data['value'] = [dayjs(filter.value[0]).format('YYYY-MM-DD'),dayjs(filter.value[1]).format('YYYY-MM-DD')]
        data['operator'] = 'between';
        break;
      case 'morethan':
        data['value'] = dayjs().subtract(filter.numberOfDays,'days').format('YYYY-MM-DD');
        data['operator'] = '<';
        break;
      case 'lessthan':
        data['value'] = dayjs().subtract(filter.numberOfDays,'days').format('YYYY-MM-DD');
        data['operator'] = '>';
        break;
      case 'exactly':
        data['value'] = dayjs().subtract(filter.numberOfDays,'days').format('YYYY-MM-DD');
        data['operator'] = '=';
        break;
      case 'on':
        data['value'] = dayjs(filter.value).format('YYYY-MM-DD');
        data['operator'] = '=';
        break;
      default:
        data['value'] =  dayjs(filter.value).format('YYYY-MM-DD');
        data['operator'] = '=';
    }
    return data;
  }

  onSelectDateType(event, dateSelectionType){
    this.currentFilter.value = null;
    this.setInputFocus();
  }

  afterDateEnter(){  
    if(this.currentFilter.dateCategory == 'between' || this.currentFilter.dateCategory == 'before' || this.currentFilter.dateCategory == 'after' || this.currentFilter.dateCategory == 'on'){
      if(this.currentFilter.value){
        this.currentFilter.implemented = true;    
      }else{
        this.currentFilter.implemented = false;
      }
    }else 
    if(this.currentFilter.dateCategory == 'morethan' || this.currentFilter.dateCategory == 'lessthan' || this.currentFilter.dateCategory == 'exactly'){
      if(this.currentFilter.numberOfDays){
        this.currentFilter.implemented = true;    
      }else{
        this.currentFilter.implemented = false;
      }
    }
  //  this.currentFilter.implemented = true;
    this.hideFilterOverlay();
    this.applyFilter();
  }

  saveToLocalStorage() {
    const filters = {
      allFilters: this.allFilters,
      isSearch: this.isSearch
    }
    this.storageHelper.setObject(this.filterStoragekey, filters);
  }

  resetDefaultClick(){    
    this.allFilters.forEach(f => {
      f.implemented = f.filterDetail.is_default;
      f.added = f.filterDetail.is_default;
      if(f.filterDetail.is_default){
        f.added = true;        
        f.value = f.filterDetail.default_value;
      }            
      this.applyFilter();

    });

  }
}
