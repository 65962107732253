import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { debounceTime, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { Subject, fromEvent } from 'rxjs';
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @ViewChild('searchTermInput', { static: false }) searchTermInput: ElementRef;
  @Output() onSearch = new EventEmitter();
  searchTerm: string;
  constructor(){}

  ngAfterViewInit(){
    fromEvent(this.searchTermInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.onSearch.emit(text);
    });
  }
  
}
