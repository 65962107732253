import { Injectable } from '@angular/core';
import {RequestService} from "../../http/request.service";
import {Address} from "@models/shared/address";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private request: RequestService) { }

  lookup(params) {
    return this.request.get('api/customer/property/lookup', params).pipe(map(res => {
      return res.addresses as Array<Address>;
    }));
  }

  lookupSC(params) {
    return this.request.get('api/admin/property/eligibility/get-address-from-sc', params).pipe(map(res => {
      return res;
    }));
  }

  lookupRebel(params) {
    return this.request.get('api/admin/property/eligibility/get-address-from-rebel-energy', params).pipe(map(res => {
      return res;
    }));
  }

  search(params) {
    return this.request.get('api/customer/property/search', params).pipe(map(res => {
      return res.addresses as Array<Address>;
    }));
  }

  update(params) {
    return this.request.post('api/admin/address/update/' + params.id, params).pipe(map(res => {
      return res;
    }));
  }
}
