import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {environment} from "@environment";
import {catchError, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(public http: HttpClient) { }

  post(url, params?): Observable<any> {
        return this.http
          .post(environment.baseUrl + url, params).pipe(
            switchMap((response: any) => {
              if (response.success) {
                return of(response);
              } else {
                return throwError(response);
              }
            }), catchError(errorResponse => {
              return throwError(errorResponse.errors);
            }));
  }

  get(url, params?): Observable<any> {
    let queryParams = this.toParam(params);
    queryParams = (queryParams) ? '?' + queryParams : '';
        return this.http
          .get(environment.baseUrl + url + queryParams).pipe(
            switchMap((response: any) => {
              if (response.success) {
                return of(response);
              } else {
                return throwError(response.errors);
              }
            }), catchError(errorResponse => {              
              return throwError(errorResponse.errors || errorResponse);
            }));
  }

  private toParam(obj) {
    if (obj) {
      return Object.keys(obj).map(key => {
        const val = obj[key];
        const prefix = encodeURIComponent(key);
        return Array.isArray(val)
          ? _toPhpQueryArray(val, prefix)
          : _toPhpQueryObject(val, prefix);
      }).join('&');


      function _toPhpQueryArray(arr, prefix) {
        return arr.map(v => prefix + '[]=' + encodeURIComponent(v)).join('&');
      }

      function _toPhpQueryObject(value, prefix) {
        if (typeof value === 'object' && value) {
          return Object.keys(value).map(k => _toPhpQueryObject(value[k], prefix + '[' + encodeURIComponent(k) + ']')).join('&');
        } else {
          return prefix + '=' + encodeURIComponent(value);
        }
      }
    }
    else {
      return null;
    }
  }

}
