<div fxLayout="column">
  <div class="filter-container" *ngIf="isSearch || allFilters?.length">
    <div fxLayout="row wrap" fxLayoutAlign="none center" fxLayoutGap="10px 10px grid">
      <div fxFlex="none" *ngIf="isSearch">
        <app-search-filter (onSearch)="onSearchUpdate($event)"></app-search-filter>
      </div>
      <ng-container *ngFor="let activeFilter of allFilters">
        <div class="active-filter" (click)="showFilterOverlay($event, activeFilter)" [ngClass]="activeFilter.name"
          [id]="activeFilter.filterName" *ngIf="activeFilter?.added">
          <p-chip [removable]="true" (onRemove)="onRemoveFilter(activeFilter)">
            <div class="content">
              <i class="filter-icon" [ngClass]="activeFilter?.filterDetail?.icon" *ngIf="activeFilter?.filterDetail?.icon"></i>
              {{activeFilter.label}} :
              <ng-container
                *ngIf="!(activeFilter?.value === undefined || activeFilter?.value === null || activeFilter?.value === '') || activeFilter?.numberOfDays; else anytemplate">
                <ng-container [ngSwitch]="activeFilter.filterDetail?.type">
                  <span *ngSwitchCase="'string'">{{activeFilter?.value }}</span>
                  <span *ngSwitchCase="'boolean'">{{activeFilter?.value.label}}</span>
                  <!-- <span *ngSwitchCase="'date'">{{activeFilter?.value | date:'d MMM, y'}}</span> -->
                  <span *ngSwitchCase="'date'">
                    <ng-container [ngSwitch]="activeFilter.dateCategory">
                      <span *ngSwitchCase="'between'">
                        <ng-container *ngIf="activeFilter?.value">
                          {{activeFilter?.value[0] | date:'d MMM, y'}} - {{ activeFilter?.value[1] ? (activeFilter?.value[1] | date:'d MMM, y') : ''}}
                        </ng-container>
                        <ng-container *ngIf="!activeFilter?.value">
                          Any
                        </ng-container>
                      </span>
                      <span *ngSwitchCase="'morethan'"> at more than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                      <span *ngSwitchCase="'lessthan'"> at less than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                      <span *ngSwitchCase="'exactly'"> at exactly {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                      <span *ngSwitchCase="'after'"> at after {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                      <span *ngSwitchCase="'before'"> at before {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                      <span *ngSwitchCase="'on'"> at on {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                      <span *ngSwitchDefault>{{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}}</span>


                    </ng-container>
                  </span>                  
                  <!-- <span *ngSwitchCase="'lookup'">{{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}</span> -->
                  <span *ngSwitchCase="'lookup'">
                    <ng-container *ngIf="activeFilter?.filterDetail?.options !== 'supplier_accounts'">
                      <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                        <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                          {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                        {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="activeFilter?.filterDetail?.options === 'supplier_accounts'">{{activeFilter?.value?.reference}} ({{activeFilter?.value?.supplier?.label}})</ng-container>
                  </span>
                  <span *ngSwitchCase="'key_value'">
                    <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                      {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                    </ng-container>
                    <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                      <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                        {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                      </ng-container>
                    </ng-container>
                  </span>
                  <span *ngSwitchDefault>
                    {{activeFilter?.value || 'Any'}}
                  </span>
                </ng-container>
              </ng-container>
              <ng-template #anytemplate>
                Any
              </ng-template>

              <!-- {{!(activeFilter?.value === undefined || activeFilter?.value === null) ? activeFilter?.value?.label ||  activeFilter?.value : 'Any'}} -->
            </div>
          </p-chip>
        </div>
      </ng-container>


      <div style="position: relative;" fxFlex="none">
        <div class="filter-button" style="width: 115px;" (click)="showListOverlay($event)">
          <i class="fa-regular fa-filter-list"></i> Add filter
        </div>
        <span class="filter-badge" *ngIf="(activeFilters$ | async).length > 0">{{(activeFilters$ | async).length}}</span>
      </div>
    </div>   
  </div>  
</div>

<p-overlayPanel #filterListOvarlay styleClass="filterList">
  <app-filters-list *ngIf="savedFilters && listVisible" 
  [filters]="allFilters" 
  [filterModule]="filterModule" 
  (onSelectFilter)="onAddFilter($event)" 
  [savedFilters]="savedFilters"
  (onApplySavedFilter)="onApplySavedFilters($event)"
  (onResetFilters)="resetFilters()"
  (onRemoveFilter)="onRemoveFilter($event)"
  (savedFilterUpdate)="savedFilterUpdate($event)"
  (savedFilterRemoved)="savedFilterRemoved($event)"
  [filterStoragekey]="filterStoragekey"
  [pageFilterModule]="pageFilterModule"
  (resetDefaultFilters)="resetDefaultClick()"
  ></app-filters-list>  
</p-overlayPanel>

<p-overlayPanel showTransitionOptions="0s cubic-bezier(0, 0, 0.12, 1)" hideTransitionOptions="0s cubic-bezier(0, 0, 0.12, 1)"  [dismissable]="true"  #filterOverlay [style]="{'width': currentFilter?.filterDetail?.type === 'date' ? '362px' : 'auto', 'min-width':'200px'}">
  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'string'">
    <div class="input-group">
      <label>{{currentFilter?.filterDetail?.label}}</label>
      <input type="text" pInputText class="input input-s input-currency" [(ngModel)]="currentFilter.searchText"
        placeholder="Search" (onChange)="onChangeText($event)">
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button type="button" class="button button-s button-ghost" (click)="cancelSearch()">Cancel</button>
      <button type="button" class="button button-s" (click)="onSearchClick()">Search</button>
    </div>
  </ng-container>
  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'integer'">
    <div class="input-group">
      <label>{{currentFilter?.filterDetail?.label}}</label>
      <input type="number" pInputText class="input input-s input-currency" [(ngModel)]="currentFilter.searchText"
        placeholder="Search" (onChange)="onChangeText($event)">
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button type="button" class="button button-s button-ghost" (click)="cancelSearch()">Cancel</button>
      <button type="button" class="button button-s" (click)="onSearchClick()">Search</button>
    </div>
  </ng-container>

  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'key_value'">
    <ng-container *ngIf="currentFilter?.filterDetail?.is_multiple">
      <div class="input-group">
        <label>{{currentFilter?.filterDetail?.label}}</label>
        <p-multiSelect [filter]="false" [options]="currentFilter?.filterDetail?.options"
          [(ngModel)]="currentFilter.value" optionLabel="label" placeholder="Select"
          (onChange)="onSelectMultiOption($event)">
          <ng-template let-option pTemplate="item">
            <div>{{option.label}}</div>
          </ng-template>
        </p-multiSelect>
      </div>
    </ng-container>
    <ng-container *ngIf="!currentFilter?.filterDetail?.is_multiple">
      <div class="input-group">
        <label>{{currentFilter?.filterDetail?.label}}</label>
        <p-dropdown [options]="currentFilter?.filterDetail?.options" optionLabel="label"
          [(ngModel)]="currentFilter.value" placeholder="Select" (onChange)="onSelectDropdownOption()"></p-dropdown>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'boolean'">
    <div class="input-group">
      <label>{{currentFilter?.filterDetail?.label}}</label>
      <p-dropdown [options]="currentFilter?.filterDetail?.options" optionLabel="label" optionValue="value" [(ngModel)]="currentFilter.value" placeholder="Select"
        (onChange)="onSelectDropdownOption()"></p-dropdown>
    </div>
  </ng-container>

  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'lookup'">
    <div class="input-group" *ngIf="currentFilter?.filterDetail?.options !== 'supplier_accounts'">
      <label>{{currentFilter?.filterDetail?.label}}</label>
      <p-autoComplete [(ngModel)]="currentFilter.value" forceSelection="true" [multiple]="currentFilter?.filterDetail?.is_multiple" placeholder="Search" field="label"
        emptyMessage="No records round" [suggestions]="currentFilter.suggestions" (onClear)="onClearLookup($event)"
        (completeMethod)="onLookup($event)" (onSelect)="onSelectLookupOption()" (onUnselect)="onSelectLookupOption()"></p-autoComplete>
    </div>
    <div class="input-group" *ngIf="currentFilter?.filterDetail?.options === 'supplier_accounts'">
      <!-- <label>{{currentFilter?.filterDetail?.label}}</label>
      <p-autoComplete [(ngModel)]="currentFilter.value" forceSelection="true" placeholder="Search" field="label"
        emptyMessage="No records round" [suggestions]="currentFilter.suggestions" (onClear)="onClearLookup($event)"
        (completeMethod)="onLookup($event)" (onSelect)="onSelectLookupOption()"></p-autoComplete>
    </div> -->
    
    <label>{{currentFilter?.filterDetail?.label}}</label>
      <p-autoComplete
        [(ngModel)]="currentFilter.value"
        forceSelection="true"
        placeholder="Search"
        emptyMessage="No records round"
        [suggestions]="currentFilter.suggestions"        
        (onClear)="onClearLookup($event)"
        (completeMethod)="onLookup($event)"
        (onSelect)="onSelectLookupOption()"
        [field]="myAccountConversionMethod"
      >
      <!-- <ng-template let-value pTemplate="selectedItem">
        {{value?.value?.reference}}:{{value?.value?.supplier?.label}}
      </ng-template> -->
        <ng-template let-account pTemplate="item">
          <div>{{account?.reference}} ({{account?.supplier?.label}})</div>
          <div [ngStyle]="{color:'var(--blue-500)'}">{{account?.property?.address?.single_line_address}}</div>
        </ng-template>
      </p-autoComplete>
    </div>
  </ng-container>

  <ng-container *ngIf="currentFilter?.filterDetail?.type == 'date'">
     <div class="input-group" *ngIf="currentFilter?.filterDetail?.only_exact_match;else moreDateSelection">
      <label>Select Date</label>
      <p-calendar [(ngModel)]="currentFilter.value"   [inline]="true" placeholder="Select date" dateFormat="dd M yy"
        (onClearClick)="onSelectDate($event)" (onSelect)="onSelectDate($event)"></p-calendar>
    </div>
    <ng-template #moreDateSelection>
      <div fxLayout="column">
        <div class="heading">
          <span>Relative</span>
        </div>
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range">        
          <p-radioButton (onClick)="onSelectDateType($event, 'relative')" [inputId]="'morethan'" name="dateCategory" [value]="'morethan'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'morethan'" class="ml-2">More than</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'morethan'">
              <input type="number"  pInputText class="input input-s input-currency" [(ngModel)]="currentFilter.numberOfDays" placeholder="" (onChange)="onChangeText($event)">
              <span>Days ago</span>
            </div>
          </div>
          
        </div>
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="(!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range)">
          <p-radioButton (onClick)="onSelectDateType($event, 'relative')" [inputId]="'exactly'" name="dateCategory" [value]="'exactly'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'exactly'" class="ml-2">Exactly</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'exactly'">
              <input type="number"  pInputText class="input input-s input-currency" [(ngModel)]="currentFilter.numberOfDays" placeholder="" (onChange)="onChangeText($event)">
              <span>Days ago</span>
            </div>
          </div>
        </div>
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range">
          <p-radioButton (onClick)="onSelectDateType($event, 'relative')" [inputId]="'lessthan'" name="dateCategory" [value]="'lessthan'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'lessthan'" class="ml-2">Less than</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'lessthan'">
              <input type="number"  pInputText class="input input-s input-currency" [(ngModel)]="currentFilter.numberOfDays" placeholder="" (onChange)="onChangeText($event)">
              <span>Days ago</span>
            </div>          
          </div>
        </div>
        <div class="heading">
          <span>Absolute</span>
        </div>
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range">
          <p-radioButton (onClick)="onSelectDateType($event, 'absolute')" [inputId]="'morethan'" name="dateCategory" [value]="'after'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'morethan'" class="ml-2">After</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'after'">
              <p-calendar [minDate]="currentFilter?.filterDetail?.min" [maxDate]="currentFilter?.filterDetail?.max" [(ngModel)]="currentFilter.value" [inline]="true" dateFormat="dd M yy"
                (onClearClick)="onSelectDate($event)" (onSelect)="onSelectDate($event)"></p-calendar>
            </div>          
          </div>
        </div>
        
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="(!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range)">
          <p-radioButton (onClick)="onSelectDateType($event, 'absolute')" [inputId]="'on'" name="dateCategory" [value]="'on'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'on'" class="ml-2">On</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'on'">
              <p-calendar [minDate]="currentFilter?.filterDetail?.min" [maxDate]="currentFilter?.filterDetail?.max" [(ngModel)]="currentFilter.value" [inline]="true" dateFormat="dd M yy"
                (onClearClick)="onSelectDate($event)" (onSelect)="onSelectDate($event)"></p-calendar>
            </div>          
          </div>
        </div>
  
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="!currentFilter?.filterDetail?.only_supports_range && currentFilter?.filterDetail?.supports_range">
          <p-radioButton (onClick)="onSelectDateType($event, 'absolute')" [inputId]="'before'" name="dateCategory" [value]="'before'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'before'" class="ml-2">Before</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'before'">
              <p-calendar [minDate]="currentFilter?.filterDetail?.min" [maxDate]="currentFilter?.filterDetail?.max"  [(ngModel)]="currentFilter.value" [inline]="true" dateFormat="dd M yy"
                (onClearClick)="onSelectDate($event)" (onSelect)="onSelectDate($event)"></p-calendar>
            </div>          
          </div>
        </div>
       
        <div class="field-checkbox" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" *ngIf="currentFilter?.filterDetail?.only_supports_range || currentFilter?.filterDetail?.supports_range">
          <p-radioButton (onClick)="onSelectDateType($event, 'absolute')" [inputId]="'between'" name="dateCategory" [value]="'between'" [(ngModel)]="currentFilter.dateCategory"></p-radioButton>
          <div fxLayout="column" fxLayoutGap="5px">
            <label [for]="'between'" class="ml-2">Between</label>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center" *ngIf="currentFilter.dateCategory == 'between'">
              <p-calendar [minDate]="currentFilter?.filterDetail?.min" [maxDate]="currentFilter?.filterDetail?.max"  [(ngModel)]="currentFilter.value" selectionMode="range" [inline]="true" dateFormat="dd M yy"
                (onClearClick)="onSelectDate($event)" (onSelect)="onSelectDate($event)"></p-calendar>
            </div>          
          </div>
        </div>      
      </div>
      <div fxLayout="row" fxLayoutAlign="end end">
        <button class="button button-s plain" (click)="afterDateEnter()">Done</button>
      </div>  
    </ng-template>         
  </ng-container>
</p-overlayPanel>