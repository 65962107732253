import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from "../../../../core/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidationService} from "../../../../shared/components/error/validation.service";
import {Subscription} from "rxjs";
import {environment} from "@environment";
import { CookieService } from '@shared-services/cookie/cookie.service';
import { TWOFA_EXPIRY } from 'src/app/core/constants/app.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('codeInput', {static: false}) codeInput: ElementRef;
  loginForm: FormGroup;
  serverSideErrors: Array<string>;
  formSubmitted = false;
  loading = true;
  authenticateSubscription: Subscription;
  logoPath: string;
  appName: string;
  auth2FA: boolean = false;
  twoFAExpiry = TWOFA_EXPIRY;
  constructor(
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute, 
    private authService: AuthenticationService, 
    private router: Router,
    private cookieService: CookieService
    ) {}

  ngOnInit(): void {
		this.logoPath = `${environment.s3bucket}logos/logo.png`;
		this.appName = environment.appName;

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
      password: ['', Validators.required],
      code: [null],
      remember_me:[],
      auth_2fa_secret: this.cookieService.getCookie('2faSecret')
    });

    this.authService.isAuthenticated.subscribe(authenticated => {
      if (authenticated) {
        this.router.navigateByUrl('admin/dashboard/home');
      }
      else {
        this.loading = false;
      }
    }, errors => {
      this.loading = false;
    });
  }

  submit() {
    this.formSubmitted = false;
    if (!this.loginForm.valid) {
      this.loginForm.markAsDirty();
      this.formSubmitted = true;
    } else {
    	let params = this.loginForm.value;
    	Object.assign(params, {
    		client_type: 'admin',
			});
    	if (! this.auth2FA) {
    	  delete params.code;
      }
      if(this.auth2FA){
        this.loginForm.value.remember_me = this.loginForm.value.remember_me ? 1 : 0;
      }else{
        delete this.loginForm.value.remember_me;
      }
     this.authenticateSubscription = this.authService.authenticate(this.loginForm.value).subscribe((res) => {
       if (res) {
         this.router.navigate([this.route.snapshot.queryParams.redirect || 'admin/dashboard/home'], { replaceUrl: true });
       } else {
         // 2fa authentication
          this.auth2FA = true;
          setTimeout(() => this.codeInput.nativeElement.focus());
       }
      }, errors => {
       this.serverSideErrors = errors;
     });
    }
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password', { state: { email: this.loginForm.value.email } });
  }
}
