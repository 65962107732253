import { Injectable } from '@angular/core';
import { RequestService } from '../../http/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  apiPrefix: string;
  bankApiPrefix: string;

  constructor(private request: RequestService) {
    this.apiPrefix = 'api/admin/supplier/';
    this.bankApiPrefix = 'api/admin/letts-pay/';
  }

  index(params) {
    return this.request.get(this.apiPrefix + 'index', params).pipe(map(res => {
      return res;
    }));
  }

  read(id) {
    return this.request.get(this.apiPrefix + 'read/' + id).pipe(map(res => {
      return res;
    }));
  }

  save(params) {
    return this.request.post(this.apiPrefix + 'save', params).pipe(map(res => {
      return res;
    }));
  }

  remove(params) {
    return this.request.post(this.apiPrefix + 'delete', params).pipe(map(res => {
      return res;
    }));
  }

  archive(params) {
    return this.request.post(this.apiPrefix + 'archive', params).pipe(map(res => {
      return res;
    }));
  }

  options(params) {
    return this.request.get(this.apiPrefix + 'options', params).pipe(map(res => {
      return res;
    }));
  }

  contactTypesOptions(){
    return this.request.get(this.apiPrefix + 'contact-detail-types/options').pipe(map(res => {
      return res;
    }));
  }

  supportSchemeTypes() {
    return this.request.get(this.apiPrefix + 'energy-support-scheme-types/options');
  }

  getBroadbandOptions() {
    return this.request.get('api/supplier/broadband/type/options');
  }

  broadbandProductTypesBySupplier(params) {
    return this.request.get('api/supplier/broadband/type/options-by-supplier', params);
  }

  getSupplierEmailsFromContract(contractId) {
    return this.request.get('api/admin/contract/get-supplier-emails/' + contractId).pipe(map(res => {
      return res;
    }));
  }

  getLogoByDomain(domain) {
    return this.request.get(this.apiPrefix + 'logo/' + domain).pipe(map(res => {
      return res;
    }));
  }

  getSupplierEmailsFromAccount(accountId) {
    return this.request.get('api/admin/utility-account/get-supplier-emails/' + accountId).pipe(map(res => {
      return res;
    }));
  }

  saveBankDetails(params) {
    return this.request.post(this.apiPrefix + 'bank-details/save', params).pipe(map(res => {
      return res;
    }));
  }

  getBankDetails(supplierId) {
    return this.request.get(this.apiPrefix + 'bank-details/' + supplierId + '/index').pipe(map(res => {
      return res;
    }));
  }

  getEmails(supplierId:number){
    return this.request.get(this.apiPrefix + 'contact-detail-types/get-emails/' + supplierId).pipe(map(res => {
      return res;
    }));
  }

  paySupplier(params) {
    return this.request.post(this.bankApiPrefix + 'pay-supplier', params).pipe(map(res => {
      return res;
    }));
  }
}
