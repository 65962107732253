import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IGanttChartRow} from "@shared/components/utilify-gantt/models/gantt-chart-row.model";
import {User} from "@models/user/user";
import {IGanttChartEvent} from "@shared/components/utilify-gantt/models/gantt-chart-event.model";

@Injectable({
  providedIn: 'root'
})
export class UtilifyGanttService {

  public nameClickedBehavior$ = new BehaviorSubject<IGanttChartRow>(null);
  public subNameClickedBehavior$ = new BehaviorSubject<IGanttChartRow>(null);
  public eventClickedBehavior$ = new BehaviorSubject<IGanttChartEvent>(null);
  public refreshGantt$ = new BehaviorSubject<any>(null);

  constructor() {
    this.nameClickedBehavior$ = new BehaviorSubject<IGanttChartRow>(null);
    this.subNameClickedBehavior$ = new BehaviorSubject<IGanttChartRow>(null);
  }

  public nameClick(row: IGanttChartRow) {
    this.nameClickedBehavior$.next(row);
  }

  public subNameClick(row: IGanttChartRow) {
    this.subNameClickedBehavior$.next(row);
  }

  public eventClick(event: IGanttChartEvent) {
    this.eventClickedBehavior$.next(event);
  }

  public refreshGantt(any) {
    this.refreshGantt$.next(any);
  }

}
