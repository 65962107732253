import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/http/pages/not-found/not-found.component';
import { ForgotPasswordComponent } from './modules/auth/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/pages/reset-password/reset-password.component';
import { ActivateAccountComponent } from './modules/auth/pages/activate-account/activate-account.component';
import {AddReadingComponent} from "./modules/public/add-reading/add-reading.component";


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import(`./modules/admin/admin.module`).then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: 'activate/:key', component: ActivateAccountComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  {
    path: 'public',
    loadChildren: () => import(`./modules/public/public.module`).then(m => m.PublicModule),
  },
  { path: '', redirectTo: 'admin', pathMatch: 'prefix' },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
