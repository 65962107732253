import { Component, Input, OnInit } from '@angular/core';
import { ORDER_STATUS_COLOUR } from 'src/app/modules/admin/orders/constants/status-colours';
import { VACANT_STATUS_COLOUR } from 'src/app/modules/admin/vacant-periods/constants/status-colours';

@Component({
  selector: 'app-status-preview',
  templateUrl: './status-preview.component.html',
  styleUrls: ['./status-preview.component.scss']
})
export class StatusPreviewComponent implements OnInit {
  @Input() status: { label: string, value: string, hex: string };
  @Input() module: string;

  constructor() { }

  ngOnInit(): void {
  }

  getStatusColour(statusValue: string) {
    if(this.module == 'vacantPeriod')
    return VACANT_STATUS_COLOUR[statusValue] || '#FFC82C';

    if(this.module == 'orders-list-pending')
    return ORDER_STATUS_COLOUR[statusValue] || '#13CE66';
  }

}
