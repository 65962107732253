<div [formGroup]="parent">
	<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="none center" fxLayoutAlign.xs="start start"
			 fxLayoutGap="10px"
			 *ngIf="!manualAddress">
		<div fxFlex="auto" fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px">
			<div fxFlex="none"><input type="text" id="postcode_search" name="postcode_search" placeholder="Postcode" formControlName="postcode_entered" class="input input-m input-small uppercase"></div>
			<div fxFlex="none"><button type="button" class="button button-m button-ghost" [promiseBtn]="loading" (click)="searchClick()">Search</button></div>
		</div>
		<div fxFlex="none" class="text-right"><a (click)="enterManualClick()">Enter manual address</a></div>
	</div>

	<div *ngIf="manualAddress">
		<a (click)="searchAddressClick()">Search</a>
	</div>

  <div class="" [hidden]="manualAddress || !addressList">
    <div class="filter-field search-field">
      <input type="text" class="input input-s" placeholder="Search" #searchTermInput pTooltip="Search.." tooltipPosition="bottom">
    </div>
  </div>

	<div class="address-list-container" *ngIf="addressList" [hidden]="manualAddress">
		<div *ngFor="let address of addressList" class="address-item" [ngClass]="{'selected': selectedAddress && selectedAddress.single_line_address === address.single_line_address}"
         (click)="selectedAddressClick(address)">
			<div fxLayout="row" fxLayoutAlign="none center">
				<div fxFlex="auto">{{ address.single_line_address }}</div>
				<div fxFlex="none"><mat-icon svgIcon="check-circle-solid" *ngIf="selectedAddress && selectedAddress.single_line_address === address.single_line_address"></mat-icon></div>
			</div>
		</div>
	</div>

	<div [hidden]="!manualAddress" class="manual-address-container">
		<div class="input-group">
			<label for="line_1" class="required">Address</label>
			<input type="text" id="line_1" name="line_1" placeholder="" formControlName="line_1" class="input input-m">
		</div>
		<div class="input-group">
			<input type="text" name="line_2" placeholder="" formControlName="line_2" class="input input-m">
		</div>
		<div class="input-group">
			<input type="text" name="line_3" placeholder="" formControlName="line_3" class="input input-m">
		</div>
		<div class="input-group">
			<label for="city" class="required">City</label>
			<input type="text" id="city" name="city" placeholder="" formControlName="city" class="input input-m">
		</div>
		<div class="input-group">
			<label for="postcode" class="required">Postcode</label>
			<input type="text" id="postcode" name="postcode" placeholder="" formControlName="postcode" class="input input-m input-small">
		</div>
	</div>
</div>
