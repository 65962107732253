<div class="input-container">
	<div class="input-search-loader" *ngIf="searchLoading">
		<mat-spinner diameter="20"></mat-spinner>
	</div>
	<input [formControl]="searchField" (click)="isOpen = !isOpen" type="text" class="input input-m input-search" cdkOverlayOrigin #trigger="cdkOverlayOrigin" placeholder="Customer/Account Search..">
</div>
	<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
							 [cdkConnectedOverlayGrowAfterOpen]="true"
							 [cdkConnectedOverlayFlexibleDimensions]="true"
							 cdkConnectedOverlayBackdropClass="backdrop-cls" [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="backdropClicked()">
		<div class="panel panel-search-results">
			<div class="no-results-container" *ngIf="!searchResults.length">
				Search for a customer by:
				<ul>
					<li>Customer account number</li>
					<li>Company name</li>
					<li>Customer's name, email or mobile</li>
					<li>Customer's addresses</li>
					<li>Account's correspondence address</li>
				</ul>
			</div>
			<div *ngFor="let user of searchResults">
				<div fxLayout="column" class="search-result" (click)="searchResultClicked(user)">
					<div fxFlex="auto" class="search-result__customer-name">{{ user.display_name }}</div>
          <div *ngFor="let account of user.accounts">
            <div fxFlex="auto" class="search-result__account-no">Account #{{ account.id}} ({{ account.label }})</div>
          </div>
				</div>
			</div>
		</div>
	</ng-template>

