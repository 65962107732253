import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import { ChangeDetectorRef, DoCheck, forwardRef, Injector, OnDestroy, Directive } from "@angular/core";
import {Subscription} from "rxjs";


@Directive()
export abstract class AbstractFormGroupControlValueAccessor implements DoCheck, OnDestroy, ControlValueAccessor, Validator {
	AppInjector: Injector;
	abstract form: FormGroup;
  subscriptions = [] as Array<Subscription>;
  submitted = false;
  onTouched: any = () => { };

  constructor(
    protected fb: FormBuilder,
    protected cdr: ChangeDetectorRef,
    protected formGroupDirective: FormGroupDirective,
		private injector: Injector
  ) {
		this.AppInjector = this.injector;
	}

  // This isn't super performant, but it's reliable
  ngDoCheck() {
    if (this.submitted !== this.formGroupDirective.submitted) {
      this.submitted = this.formGroupDirective.submitted;
      if (this.submitted) {
        this.form.markAllAsTouched();
      }
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  writeValue(obj: any): void {
    // when you call `reset()` on your parent form, it will always set `null`
    // though I use this as an indicator to reset this FormGroup, too.
    if (obj === null) {
      this.form.reset();
    } else {
      this.form.patchValue(obj, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(fn));
  }

  // unused for now
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : getAllErrors(this.form);
  }
}

export function getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    if (control) {
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
    }
    return acc;
  }, {} as { [key: string]: any; });
  return hasError ? result : null;
}

export function ngValueAccessorProviderFactory(val: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => val),
    multi: true
  };
}

export function ngValidatorsProviderFactory(val: any) {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => val),
    multi: true
  };
}
