import {Component, OnInit} from '@angular/core';
import {environment} from "@environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../../../../shared/components/error/validation.service";
import {User} from "@models/user/user";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../../core/authentication/authentication.service";

@Component({
	selector: 'app-activate-account',
	templateUrl: './activate-account.component.html',
	styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
	logoPath: string;
	appName: string;
	loading: boolean;
	activateForm: FormGroup;
	formSubmitted: boolean;
	serverSideErrors: Array<string>;
	processing: boolean;
	key: string;
	user: User;
	tokenExpired: boolean;
	errors: Array<string>;

	constructor(private formBuilder: FormBuilder,
							private authService: AuthenticationService,
							private router: Router,
							private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.loading = true;
		this.logoPath = `${environment.s3bucket}logos/logo.png`;
		this.appName = environment.appName;

		this.activateForm = this.formBuilder.group({
			password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator])],
			password_confirm: ['', [Validators.required, (control => ValidationService.confirmPassword(control, this.activateForm, 'password'))]],
			// accept: ['', Validators.requiredTrue],
		});

		this.route.params.subscribe(params => {
			if (params.key) {
				this.key = params.key;
				this.authService.validateAccessToken(params.key).subscribe((user: User) => {
					this.user = user;
					this.loading = false;
				}, errors => {
					this.errors = errors;
					this.tokenExpired = true;
					this.loading = false;
				});
			}
		});
	}

	submit() {
		this.formSubmitted = false;
		if (!this.activateForm.valid) {
			this.activateForm.markAsDirty();
			this.formSubmitted = true;
		} else {
			this.processing = true;
			let params = {
				password: this.activateForm.value.password,
				key: this.key,
			};
			this.authService.activateAccount(params).subscribe((res) => {
				this.processing = false
				this.router.navigateByUrl('admin/dashboard').then();
			}, errors => {
				this.serverSideErrors = errors;
			});
		}
	}
}
