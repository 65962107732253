import {
  Injectable
} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class PushNotificationsService {
  public permission: Permission;

  constructor() {
    this.permission = this.isSupported() ? 'default' : 'denied';
  }

  public isSupported(): boolean {
    return 'Notification' in window;
  }

  requestPermission(): void {
    let self = this;
    if ('Notification' in window) {
      Notification.requestPermission(function(status) {
        return self.permission = status;
      });
    }
  }

  create(title: string, options ? : PushNotification): any {
    let self = this;
    return new Observable(function(obs) {
      if (!('Notification' in window)) {
        console.log('Notifications are not available in this environment');
        obs.complete();
      }
      if (self.permission !== 'granted') {
        console.log("The user hasn't granted you permission to send push notifications");
        obs.complete();
      }
      let _notify = new Notification(title, options);
      _notify.onshow = function(e) {
        return obs.next({
          notification: _notify,
          options: options,
          event: e
        });
      };
      _notify.onclick = function(e) {
        window.focus();
        return obs.next({
          notification: _notify,
          options: options,
          event: e
        });
      };
      _notify.onerror = function(e) {
        return obs.error({
          notification: _notify,
          options: options,
          event: e
        });
      };
      _notify.onclose = function() {
        return obs.complete();
      };
    });    
  }

  generateNotification(source: any ): Observable<any> {
    let self = this;
    let options = {
      body: source.alertContent,
      url: source.url,
      // icon: "https://utilitymix-resources.s3-eu-west-1.amazonaws.com/logos/um-slack.png"
    };
    return self.create(source.title, options);

  }
}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
  body ? : string;
  icon ? : string;
  tag ? : string;
  data ? : any;
  renotify ? : boolean;
  silent ? : boolean;
  sound ? : string;
  noscreen ? : boolean;
  sticky ? : boolean;
  dir ? : 'auto' | 'ltr' | 'rtl';
  lang ? : string;
  vibrate ? : number[];
  url ? : string;
}
