import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  authenticated: boolean;
  isAdmin: boolean;
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      return new Promise(res => {
        this.authService.isAuthenticated.subscribe(
            (authenticated) => {
                if (authenticated === true) {
                  this.authService.isAdmin().subscribe(isAdmin => {
                    res(authenticated && isAdmin);
                  });
                } else {
                  this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
                  res(false);
                }
            },
            (error) => {
              this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
                res(false);
            }
        );
    });

  //  this.authService.isAuthenticated.subscribe((res:any)=> {
  //       this.authenticated = res;
  //       if(!res) {
  //         this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
  //         return of(false)
  //       }
  //     })

  //   return this.authService.isAuthenticated.pipe(
  //     switchMap((authenticated) =>
  //      authenticated && this.authService.isAdmin()

  //   ));
  }
}
