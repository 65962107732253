import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterService } from '@shared-services/filter/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})
export class SaveFilterComponent {
  @Input() filters: Array<any>;
  @Input() filterModule: string;
  @Input() selectedFilter: any;
  @Input() allFilters:any;
  @Input() pageFilterModule: string;
  @Output() onSaveEditFilter  = new EventEmitter();
  filterForm: FormGroup;
  formSubmitted: boolean = false
  filterData: any;
  submitted: boolean;
  saveSubscription: Subscription;
  _filters:any;
  constructor(private formBulder: FormBuilder, private filterService: FilterService, private toastr: ToastrService){}
  ngOnInit(): void {
    this.formInit();    
    if(this.selectedFilter) {
      this._filters = [];
      Object.keys(this.selectedFilter.filter_criteria).forEach(key => {
        const filter = this.allFilters.find(f => f.filterName == key);        
        if(filter){
          let data = {
            filterName: key,
            applied: true,
            added: true,
            label: filter.label,
            filterDetail: filter.filterDetail,
            value: this.selectedFilter.filter_criteria[key].value              
        }
        if(filter.filterDetail.type == 'date'){
          data['numberOfDays'] = this.selectedFilter.filter_criteria[key].numberOfDays;
          data['dateCategory'] = this.selectedFilter.filter_criteria[key].dateCategory;
        }
          this._filters.push(data)
        }       
      })
    }else{
      this._filters = [].concat(this.filters);
    }    
  } 

  formInit() {
    this.filterForm = this.formBulder.group({      
      filter_name: ['', [Validators.required]],
      is_public: [false, [Validators.required]],
      module: this.pageFilterModule
    })
    if(this.selectedFilter)
    this.filterForm.patchValue(this.selectedFilter)
  }

  get isPublic(){
    return this.filterForm.controls['is_public'] as FormControl;
  }

  removeFilter(i){
    this._filters.splice(i, 1);
  }

  submit(){   
    if (!this.filterForm.valid) {
      this.filterForm.markAsDirty();
      this.formSubmitted = true;
      return;
    } 
    
    this.submitted = true;
    const filter = {}
    this._filters.forEach(f => {
      filter[f.filterName] = {value:f.value};
      if(f.filterDetail.type == 'date'){
        if(f.numberOfDays)
        filter[f.filterName]['numberOfDays'] = f.numberOfDays;
        if(f.dateCategory)
        filter[f.filterName]['dateCategory'] = f.dateCategory;
      }
    })
    let payload = {
      ...this.filterForm.value,
      filter_criteria:filter,
      is_public: this.filterForm.value.is_public
    }
    if(this.selectedFilter)
    payload = Object.assign(payload, {page_filter:{id:this.selectedFilter.id}})
    if (this.filterForm.valid){
      this.saveSubscription = this.filterService.saveModuleFilter(payload).subscribe(res => { 
        const msg = this.selectedFilter ? 'Filter updated successfully' : 'Filter created successfully';
        this.toastr.success(msg)       
        this.onSaveEditFilter.emit(res.page_filter);
      });
    }
  }
}
