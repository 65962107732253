
<div *ngFor="let option of options" class="panel-container" [ngClass]="sizeMode">
	<div fxLayout="row" fxLayoutAlign="none center" class="panel" (click)="optionSelected(option)"  [ngClass]="{'selected': selectedOption && option.id === selectedOption.id}">
		<div fxFlex="none" *ngIf="iconColumn" class="icon">
			<mat-icon [svgIcon]="iconPath(option[iconColumn])"></mat-icon>
		</div>
		<div fxFlex="auto" fxLayout="column">
			<div fxFlex="auto" class="label-column">{{ option.label }}</div>
			<div fxFlex="auto" *ngIf="descColumn" class="description-column">{{ option[descColumn] }}</div>
		</div>
		<div fxFlex="none" class="icon-tick">
			<mat-icon svgIcon="check-circle-solid" *ngIf="selectedOption && option.id === selectedOption.id"></mat-icon>
		</div>
	</div>
</div>
