import {Component, Input, OnInit} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {pulseOnEnterAnimation} from "angular-animations";

@Component({
  selector: 'app-stepper-steps',
  templateUrl: './stepper-steps.component.html',
  styleUrls: ['./stepper-steps.component.scss'],
	animations: [
		pulseOnEnterAnimation(),
	]
})
export class StepperStepsComponent implements OnInit {
	@Input() stepper: MatStepper;
	@Input() steps: any;
	@Input() selectedIndex: number;
	@Input() size: string;

  constructor() { }

  ngOnInit(): void {
  }

	goToStep(i: number, step: any) {
  	if (step.completed) {
			this.stepper.selectedIndex = i;
		}
	}
}
