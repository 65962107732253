import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";
import {ValidationService} from "../../../../shared/components/error/validation.service";
import {AuthenticationService} from "../../../../core/authentication/authentication.service";
import { ToastrService } from 'ngx-toastr';
import { environment} from "@environment";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  forgotPasswordSubscription: Subscription;
  loading: boolean;
  formSubmitted: boolean;
  serverSideErrors: Array<string>;
  appName: string;
  logoPath: string;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private toastr: ToastrService) { }

  ngOnInit(): void {
		this.logoPath = `${environment.s3bucket}logos/logo.png`;
		this.appName = environment.appName;

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
    });

    this.activatedRoute.paramMap
      .pipe(map(() => window.history.state)).subscribe(state => {
        if (state.email) {
          this.forgotPasswordForm.patchValue({
            email: state.email,
          });
        }
    });

    this.loading = false;
  }

  submit() {
    this.forgotPasswordSubscription = this.authenticationService.forgotPassword(this.forgotPasswordForm.value).subscribe(res => {
      this.toastr.success("We've sent you an email with reset instructions");
    }, errors => {
      this.serverSideErrors = errors;
    });
  }
}
