import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IGanttChartRow} from "@shared/components/utilify-gantt/models/gantt-chart-row.model";
import {IGanttChartEvent} from "@shared/components/utilify-gantt/models/gantt-chart-event.model";
import {DateHelperService} from "@shared/classes/DateHelperService";
import {MonthAxis} from "@shared/components/utilify-gantt/models/month-axis.model";
import {UtilifyGanttService} from "@shared/components/utilify-gantt/utilify-gantt.service";
import dayjs from "dayjs";
import moment from 'moment';
@Component({
  selector: 'app-utilify-gantt-row',
  templateUrl: './utilify-gantt-row.component.html',
  styleUrls: ['./utilify-gantt-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtilifyGanttRowComponent implements OnInit {

  @Input() rows: IGanttChartRow[];
  @Input() chartPeriodDays: number;
  @Input() monthAxis: MonthAxis[];
  @Input() startDate: Date;
  currentMonth: string;

  constructor(private ganttService: UtilifyGanttService) { }

  ngOnInit(): void {
    this.currentMonth = dayjs().format('MMM YY');
  }


  /** Given an event calculate the percentage of days over the total gantt chart period */
  getEventDurationPercentage(event: IGanttChartEvent): number {
    const eventDays = DateHelperService.dateDifference(event.endDate, event.startDate);
    return (eventDays/this.chartPeriodDays) * 100;

  }

  /** Given an date the percentage of days over the total gantt chart period */
  getEventOffsetPercentage(eventStartDate: Date): number {
    const daysPriorToEventStart = DateHelperService.dateDifference(eventStartDate, this.startDate);
    const multiplier = moment(eventStartDate).isAfter(this.startDate) ? 100 : -100;
    return ((daysPriorToEventStart-1)/this.chartPeriodDays)*multiplier;
  }

  getBackgroundColor(event: IGanttChartEvent) {
    if (event.inFuture) {
      return 'transparent';
    } else {
      return event.backgroundColour;
    }
  }

  getForegroundColor(event: IGanttChartEvent) {
    return event.foregroundColor;
  }

  nameClick(event, row: IGanttChartRow) {
    event.stopPropagation();
    this.ganttService.nameClick(row);
  }

  subNameClick(event, row: IGanttChartRow) {
    event.stopPropagation();
    this.ganttService.subNameClick(row);
  }

  eventClick(event: IGanttChartEvent) {
    this.ganttService.eventClick(event);
  }
}
