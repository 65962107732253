import {Component, Input, OnInit} from '@angular/core';
import {DateHelperService} from "@shared/classes/DateHelperService";
import {MonthAxis} from "@shared/components/utilify-gantt/models/month-axis.model";
import {IGanttChartEvent} from "@shared/components/utilify-gantt/models/gantt-chart-event.model";
import dayjs from "dayjs";
import {IGanttChartRow} from "@shared/components/utilify-gantt/models/gantt-chart-row.model";


@Component({
  selector: 'app-utilify-gantt',
  templateUrl: './utilify-gantt.component.html',
  styleUrls: ['./utilify-gantt.component.scss']
})
export class UtilifyGanttComponent implements OnInit {

  @Input() rows: IGanttChartRow[];
  @Input() set datePeriod (period) {
    if (period) {
      this.startDate = period[0];
      this.endDate = period[1];
      this.loadChartPeriods();
    }
  }
  startDate: Date;
  endDate: Date;
  chartPeriodDays: number;
  monthAxis: MonthAxis[];
  currentMonth: string;
  colourPallete = ['#7C4DFF',
    '#81c784',
    '#e53935',
    '#FF8A80',
    '#303F9F',
    '#40C4FF',
    '#006064',
    '#FF8A65']

  constructor(
  ) {

  }

  ngOnInit(): void {
    this.currentMonth = dayjs().format('MMM YY');
  }

  loadChartPeriods() {
    this.chartPeriodDays = DateHelperService.dateDifference(this.endDate, this.startDate, true);
    this.monthAxis = this.getMonths(this.startDate, this.endDate);
  }

  /** Given a start and end date will return full months between period along with month names and
   * relative duration percentages for each month
   */
  getMonths(startDate: Date, endDate: Date): MonthAxis[] {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    let monthsCount = end.diff(start, 'month');

    const totalDurationDays = DateHelperService.dateDifference(startDate, endDate, true);
    let months: MonthAxis[] = [];
    for(let i = 0; i<= monthsCount; i++) {
      const adjustedStartDate = DateHelperService.addMonths(startDate, i);
      const monthName = DateHelperService.getMonthName(adjustedStartDate);
      const monthYear = DateHelperService.getMonthYear(adjustedStartDate);
      const daysInMonth = DateHelperService.daysInMonth(adjustedStartDate);
      // const monthDurationPercentage = daysInMonth/totalDurationDays * 100;
      const monthDurationPercentage = 25;
      months.push({monthName: monthName, monthYear: monthYear, monthDurationPercentage: monthDurationPercentage, date: adjustedStartDate});
    }
    return months;
  }

  /** Given colour for */
  getColour(rowIndex: number): string {
    if(this.rows.length < rowIndex) {
      return '#64b5f6';
    }

    return this.colourPallete[rowIndex];
  }

  calculateGanttContainerWidth() {
    if (this.monthAxis.length <= 24) {
      return 100;
    }
    return Math.round(this.monthAxis.length / 24) * 100;
  }

}
