import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Editor } from 'primeng/editor';
import Mention from 'quill-mention';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss']
})
export class QuillEditorComponent implements OnInit {

  @ViewChild('editorComponent', { static: false }) editor: Editor
  @Output() onUpdateMentions:EventEmitter<any> = new EventEmitter();
  @Input() options:Array<any>
  @Input() control: FormControl;

  inputHeight: number;
  quilInstance:any

  constructor() {}
   
  ngOnInit(): void {
    this.inputHeight = 110;
  }

  onEditorInit(event: any) {
    event.editor.focus();
  }
  ngAfterViewInit() {
    this.quillMention();
    this.control.valueChanges
    .pipe(
      debounceTime(300)
    )
    .subscribe(()=>{
      this.onUpdateMentions.emit(this.quilInstance.getContents().ops.filter(content => content.insert.mention).map(content => content.insert.mention.id))
    })
  }

  async quillMention() {   
    if (this.editor) {
      const quilInstance = this.quilInstance = this.editor.getQuill();

      new Mention(quilInstance, {
        mentionDenotationChars: ['@'],
        source:  (
          searchTerm: string,
          renderList: (data: any, searchText: string) => void,
          mentionChar: string,
        )=> {
          if (searchTerm.length === 0) {
            renderList(this.options, searchTerm);
          } else {
            let matches = this.options.filter((entry) => {
              if (
                entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1
              ) {
                return entry;
              }
            });
            renderList(matches, searchTerm);
          }
        },
      });
    }
  }
}
