import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common'

@Directive({
  selector: '[highlightEvent]'
})
export class HighlightEventDirective {
  @Input() account_id;

  @HostListener('mouseenter') onMouseEnter(){    
    const elements = document.querySelectorAll(this.account_id);
    if(elements.length && elements.length > 1){
      elements.forEach((element) => {
        element.classList.add('highlight-me');
      });
    }
    
    //this.renderer2.removeClass(this.elRef.nativeElement,'highlight-me');
  }
  @HostListener('mouseleave') onMouseOut(){    
    const elements = document.querySelectorAll(this.account_id);
    elements.forEach((element) => {
      element.classList.remove('highlight-me');
    });
  }
  constructor(private elRef:ElementRef,private renderer2:Renderer2) {}
}
