import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollTracker]',
})
export class ScrollTrackerDirective {

  @Output() scrolled = new EventEmitter<any>();

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    let endReached = false;

    if (event.target.scrollTop === limit) {
      endReached = true;
    }
    this.scrolled.emit({
      pos: event.target.scrollTop,
      endReached
    });
  }
}
