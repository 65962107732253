import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountService } from '@shared-services/account/account.service';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '@models/user/user';
import { UserService } from '@shared-services/user/user.service';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
  isOpen: boolean;
  searchField: FormControl = new FormControl();
  searchResults: User[];
  searchLoading: boolean;
  @Output() selected = new EventEmitter<User>();

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.searchResults = [];
    this.searchField.valueChanges.pipe(
      debounceTime(200),
      filter(searchTerm => searchTerm !== ''),
      switchMap(searchTerm => {
          this.searchLoading = true;
          const params = {
            filter: {
              search: searchTerm,
              roles: [
                'Customer Owner',
                'Office Manager',
                'Property Manager'
              ]
            }
          };
          return this.userService.index(params);
        }
      ))
      .subscribe((res) => {
        this.searchResults = res.user_collection;
        this.searchLoading = false;
      });
  }

  backdropClicked() {
    this.isOpen = !this.isOpen;
  }

  searchResultClicked(user: User) {
    this.router.navigateByUrl(`/customer-service/${user.id}`).then();
  }
}
