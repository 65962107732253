import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Subscription} from 'rxjs';
import {ValidationService} from "../../error/validation.service";
import {
  AbstractFormGroupControlValueAccessor,
  ngValidatorsProviderFactory,
  ngValueAccessorProviderFactory
} from "../../../classes/AbstractFormGroupControlValueAccessor";


// describes what the return value of the form control will look like
export interface AddressFormValues {
  line_1: string;
  line_2: string;
  line_3: string;
  city: string;
  postcode: number;
}

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ngValueAccessorProviderFactory(AddressFormComponent),
    ngValidatorsProviderFactory(AddressFormComponent)
  ],
})
export class AddressFormComponent extends AbstractFormGroupControlValueAccessor {

  subscriptions: Subscription[] = [];
  form = this.fb.group({
    line_1: ['', Validators.required],
    line_2: [],
    line_3: [],
    city: ['', Validators.required],
    postcode: ['', Validators.compose([Validators.required, ValidationService.postCodeValidator])],
  });

  get line_1(): FormControl {
    return this.form.get('line_1') as FormControl;
  }

  get line_2(): FormControl {
    return this.form.get('line_2') as FormControl;
  }

  get line_3(): FormControl {
    return this.form.get('line_3') as FormControl;
  }

  get city(): FormControl {
    return this.form.get('city') as FormControl;
  }

  get postcode(): FormControl {
    return this.form.get('postcode') as FormControl;
  }
}
