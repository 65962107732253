import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconService} from "./core/services/icon.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import {SocketService} from "@shared/services/socket.service";
import {PushNotificationsService} from "@shared/services/PushNotificationService";
import { ToastrModule } from 'ngx-toastr';
import { QuillModule } from 'ngx-quill'
import 'quill-mention';
import { ClipboardModule } from 'ngx-clipboard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [
    AppComponent,
  ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		FlexLayoutModule,
    QuillModule.forRoot(),    
    ClipboardModule,
    ConfirmDialogModule,
    ToastModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
  providers: [{provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' }, PushNotificationsService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconService: IconService, private socketService: SocketService) {
    iconService.registerIcons();
  }
}