
<div fxLayout="row" fxLayoutAlign="center start" class="forgot-password-component" *ngIf="!loading">
	<div fxLayout="column" fxLayoutAlign="start center" fxFill>
		<div class="logo-container">
			<img src="{{ logoPath }}" alt="logo" class="logo"/>
		</div>
		<div class="panel panel--center panel--forgot-password">
			<h3 class="text-left">Forgot password</h3>

			<form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" #ngForm="ngForm" [ngClass]="{'ng-submitted': formSubmitted}">
				<form-error-summary [formGroup]="forgotPasswordForm" [serverSideErrors]="serverSideErrors" [submitted]="formSubmitted"></form-error-summary>
				<div class="input-group">
					<label for="email">Email</label>
					<input type="text" id="email" name="email" placeholder="email" formControlName="email" class="input input-m">
				</div>
				<button type="submit" class="button button-l button-full-width" [promiseBtn]="forgotPasswordSubscription">Reset password</button>
			</form>
		</div>

		<div class="login">
			<a routerLink="/login">Login</a>
		</div>

	</div>
</div>

