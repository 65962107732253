<div fxLayout="row" fxLayoutAlign="space-between center">  
</div>
<div fxLayout="column">
    <span class="f-heading">Filters:</span>
    <div fxLayout="column" fxLayoutGap="16px" style="margin-top: 16px;">
        <ng-container *ngFor="let activeFilter of _filters;let i=index">
            <div class="active-filter">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="content">
                        <i class="filter-icon" [ngClass]="activeFilter?.filterDetail?.icon" *ngIf="activeFilter?.filterDetail?.icon"></i>
                        {{activeFilter.label}} :
                        <ng-container
                          *ngIf="!(activeFilter?.value === undefined || activeFilter?.value === null || activeFilter?.value === '') || activeFilter?.numberOfDays; else anytemplate">
                          <ng-container [ngSwitch]="activeFilter.filterDetail?.type">
                            <span *ngSwitchCase="'string'">{{activeFilter?.value }}</span>
                            <span *ngSwitchCase="'boolean'">{{activeFilter?.value.label}}</span>
                            <!-- <span *ngSwitchCase="'date'">{{activeFilter?.value | date:'d MMM, y'}}</span> -->
                            <span *ngSwitchCase="'date'">
                              <ng-container [ngSwitch]="activeFilter.dateCategory">
                                <span *ngSwitchCase="'between'">
                                  <ng-container *ngIf="activeFilter?.value">
                                    {{activeFilter?.value[0] | date:'d MMM, y'}} - {{ activeFilter?.value[1] ? (activeFilter?.value[1] | date:'d MMM, y') : ''}}
                                  </ng-container>
                                  <ng-container *ngIf="!activeFilter?.value">
                                    Any
                                  </ng-container>
                                </span>
                                <span *ngSwitchCase="'morethan'"> at more than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                <span *ngSwitchCase="'lessthan'"> at less than {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                <span *ngSwitchCase="'exactly'"> at exactly {{activeFilter?.numberOfDays}} day{{activeFilter?.numberOfDays > 1 ? 's' : ''}} ago </span>
                                <span *ngSwitchCase="'after'"> at after {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                <span *ngSwitchCase="'before'"> at before {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                <span *ngSwitchCase="'on'"> at on {{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}} </span>
                                <span *ngSwitchDefault>{{activeFilter?.value ? (activeFilter?.value | date:'d MMM, y') : 'Any'}}</span>
          
          
                              </ng-container>
                            </span>                  
                            <!-- <span *ngSwitchCase="'lookup'">{{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}</span> -->
                            <span *ngSwitchCase="'lookup'">
                              <ng-container *ngIf="activeFilter?.filterDetail?.options !== 'supplier_accounts'">
                                <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                                  <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                                    {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                                  {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="activeFilter?.filterDetail?.options === 'supplier_accounts'">{{activeFilter?.value?.reference}} ({{activeFilter?.value?.supplier?.label}})</ng-container>
                            </span>
                            <span *ngSwitchCase="'key_value'">
                              <ng-container *ngIf="!activeFilter?.filterDetail?.is_multiple">
                                {{activeFilter?.value[activeFilter?.filterDetail?.extra?.label]}}
                              </ng-container>
                              <ng-container *ngIf="activeFilter?.filterDetail?.is_multiple">
                                <ng-container *ngFor="let option of activeFilter?.value;let last = last">
                                  {{option[activeFilter?.filterDetail?.extra?.label]}}<span *ngIf="!last">, </span>
                                </ng-container>
                              </ng-container>
                            </span>
                            <span *ngSwitchDefault>
                              {{activeFilter?.value || 'Any'}}
                            </span>
                          </ng-container>
                        </ng-container>
                        <ng-template #anytemplate>
                          Any
                        </ng-template>
          
                        <!-- {{!(activeFilter?.value === undefined || activeFilter?.value === null) ? activeFilter?.value?.label ||  activeFilter?.value : 'Any'}} -->
                    </div>
                    <i class="fa-regular fa-circle-xmark close-fa-icon" (click)="removeFilter(i)"></i>
                </div>
               
              
            </div>
        </ng-container>
    </div>
    <p-divider *ngIf="_filters?.length"></p-divider>
    <div>
        <form [formGroup]="filterForm" (ngSubmit)="submit()" autocomplete="off" [ngClass]="{'ng-submitted': submitted}" *ngIf="filterForm">
           <form-error-summary [formGroup]="filterForm"
          [submitted]="submitted"></form-error-summary>             
        <div class="input-group">
          <label>Name:</label>
          <input type="text"  class="input input-s" formControlName="filter_name" placeholder="name">
        </div>
        <div class="input-group" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <p-inputSwitch formControlName="is_public"></p-inputSwitch>
            <label>{{!isPublic.value ? 'Private' : 'Public'}}</label>
      </div>
        <!--  -->

        <div fxLayout="row" fxLayoutAlign="end center" class="footer">
          <button type="submit" class="button button-s" [promiseBtn]="saveSubscription" [disabled]="!_filters.length">Save</button>
        </div>      
      </form>      
      </div>

    
</div>
