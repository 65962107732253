import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(!value)
    return 'N/A';   
    if(args.length && args[0] == 'background'){
     return this.updateColor(value);
    }

    if(args.length && args[0] == 'color'){
      return this.getColor(value);
     }

    if(args.length && args[0] == 'onlyfirstLetter'){
      return value.replace(/-|\s/g,"").match(/\b(\w)/g).join('').toUpperCase();  
    }
    
    return value.match(/\b(\w)/g).join('').toUpperCase();
    
  }

  stringToHslColor(str, s, l) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let h = hash % 360;    
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }

  updateColor(userName, s=30, l=55) {   
    let textColor = l > 81 ? '#555' : '#fff';
    
    let hexColor = this.stringToHslColor(userName, s , l);
    return {'background-color':hexColor, 'color':textColor}
    //return hexColor;
   
    
  }

  getColor(userName, s=60, l=55) {   
    let hexColor = this.stringToHslColor(userName, s , l);
    return {'color':hexColor}
    //return hexColor;
   
    
  }

}
